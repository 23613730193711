import React, { useState, useEffect, useLayoutEffect } from 'react'
import { CButton, CModal, CFormInput, CModalHeader, CFormSwitch, CFormSelect, CModalTitle, CAlert, CModalBody, CModalFooter, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilAddressBook } from '@coreui/icons';
import axios from 'axios'
import ReactLoading from 'react-loading';
import exportFromJSON from "export-from-json";
import './Historial.css'

function Historial() {
  let [loading, setLoading] = useState(true)
  let [visible1, setVisible1] = useState(false)
  let [historial, setHistorial] = useState([])
  let [monthDisplay, setMonthDisplay] = useState('')
  let [yearDisplay, setYearDisplay] = useState('')
  let [historialDisplay, setHistorialDisplay] = useState([])

  function getHistorial() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllHistorialDescuentos`)
      .then((response) => { setHistorial(response.data.data); setLoading(false) })
      .catch((err) => { console.log(err) })
  }

  function exportarExcel() {
    let datos = []
    let titulo = historialDisplay.map((v) => v.titulo)
    let nombre = historialDisplay.map((v) => v.nombre)
    let codigoFamilia = historialDisplay.map((v) => v.codigoFamilia)
    let apellidoFamilia = historialDisplay.map((v) => v.apellidoFamilia)
    let cel = historialDisplay.map((v) => v.cel)
    let mail = historialDisplay.map((v) => v.mail)
    let cuantoVendio = historialDisplay.map((v) => v.cuantoVendio)
    let descuento = historialDisplay.map((v) => v.descuento)

    for (var i = 0; i < titulo.length; i++) {
      if (nombre[i] != "N/A") {
        datos.push({
          'Titulo': titulo[i],
          'Nombre': nombre[i],
          'Codigo de Familia': codigoFamilia[i],
          'Apellido de Familia': apellidoFamilia[i],
          'Celular': cel[i],
          'Mail': mail[i],
          'Vendido': `$${cuantoVendio[i]}`,
          'Descuento Acumulado': `$${descuento[i]}`,
        })
      }
    }

    exportFromJSON({ data: datos, fileName: `Reporte_Historial_${monthDisplay}_${yearDisplay}`, exportType: "csv" })
  }

  useLayoutEffect(() => {
    getHistorial()
  }, [])

  return (
    <>
      <div className='centro'>
        <CModal className="modal-xl" visible={visible1} onClose={() => setVisible1(false)}>
          <CModalHeader onClose={() => setVisible1(false)}>
            <CModalTitle>Descuentos {monthDisplay} {yearDisplay}</CModalTitle>
          </CModalHeader>

          <CModalBody>
            <CTable responsive color="dark" hover>
              <CTableHead>
                <CTableRow color='info'>
                  <CTableHeaderCell>Titulo</CTableHeaderCell>
                  <CTableHeaderCell>Nombre</CTableHeaderCell>
                  <CTableHeaderCell>Codigo de Familia</CTableHeaderCell>
                  <CTableHeaderCell>Apellido Familia</CTableHeaderCell>
                  <CTableHeaderCell>Cel</CTableHeaderCell>
                  <CTableHeaderCell>Mail</CTableHeaderCell>
                  <CTableHeaderCell>Vendido</CTableHeaderCell>
                  <CTableHeaderCell>Descuento</CTableHeaderCell>
                  <CTableHeaderCell><CTableHeaderCell><CButton color="success" shape="rounded-pill" onClick={exportarExcel}><i className="far fa-file-excel"></i> XLS</CButton></CTableHeaderCell></CTableHeaderCell>
                </CTableRow>
              </CTableHead>

              <CTableBody>
                {
                  historialDisplay.map((item) => {
                    return (
                      item.nombre != 'N/A' ?
                        <>
                          <CTableRow>
                            <CTableDataCell>{item.titulo}</CTableDataCell>
                            <CTableDataCell>{item.nombre}</CTableDataCell>
                            <CTableDataCell>{item.codigoFamilia}</CTableDataCell>
                            <CTableDataCell>{item.apellidoFamilia}</CTableDataCell>
                            <CTableDataCell>{item.cel}</CTableDataCell>
                            <CTableDataCell>{item.mail}</CTableDataCell>
                            <CTableDataCell>${item.cuantoVendio}</CTableDataCell>
                            <CTableDataCell>${item.descuento}</CTableDataCell>
                            <CTableDataCell></CTableDataCell>
                          </CTableRow>
                        </>
                        : null
                    )
                  })
                }
              </CTableBody>
            </CTable>
          </CModalBody>

          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible1(false)}>Cerrar</CButton>
          </CModalFooter>
        </CModal>

        {
          !loading ?
            <>
              <CTable style={{ width: '60%', margin: 'auto' }} responsive color="dark" hover>
                <CTableHead>
                  <CTableRow color='info'>
                    <CTableHeaderCell>Mes</CTableHeaderCell>
                    <CTableHeaderCell>Historial</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>

                <CTableBody>
                  {
                    historial.length > 0 ?
                      <>
                        {
                          historial.map((item) => {
                            return (
                              <>
                                <CTableRow>
                                  <CTableDataCell>{item.month} {item.year}</CTableDataCell>
                                  <CTableDataCell><CButton color='info' shape="rounded-pill" onClick={() => { setVisible1(true); setYearDisplay(item.year); setMonthDisplay(item.month); setHistorialDisplay(item.descuentos) }}><CIcon icon={cilAddressBook} size="xl" /></CButton></CTableDataCell>
                                </CTableRow>
                              </>
                            )
                          })
                        }
                      </>
                      :
                      <>
                        <CTableRow>
                          <CTableDataCell colSpan="7">Sin Resultados</CTableDataCell>
                        </CTableRow>
                      </>
                  }

                </CTableBody>
              </CTable>
            </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }
      </div>
    </>
  )
}

export default Historial
