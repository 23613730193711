import React, { useState, useEffect, useLayoutEffect } from 'react'
import { CButton, CModal, CFormInput, CModalHeader, CFormSwitch, CFormSelect, CModalTitle, CAlert, CModalBody, CModalFooter, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilAddressBook } from '@coreui/icons';
import axios from 'axios'
import ReactLoading from 'react-loading';
import exportFromJSON from "export-from-json";
import './Donadores.css'

function Donadores() {
  let [loading, setLoading] = useState(true)
  const [visibleAlert1, setVisibleAlert1] = useState(false)
  const [visibleAlert2, setVisibleAlert2] = useState(false)
  const [visibleAlert3, setVisibleAlert3] = useState(false)
  const [visibleAlert4, setVisibleAlert4] = useState(false)
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  let [donadoresOG, setDonadoresOG] = useState([])
  let [donadores, setDonadores] = useState([])
  let [nombresDisplay, setNombresDisplay] = useState([])
  let [datos, setDatos] = useState([])

  let [nombreCompleto, setNombreCompleto] = useState()
  let [cel, setCel] = useState()
  let [mail, setMail] = useState()
  let [nombres, setNombres] = useState([])
  let [a, setA] = useState()
  let [acabo, setAcabo] = useState(false)

  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara

  function getDonadores() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllDonadores`)
      .then((response) => { setDonadores(response.data.data); setDonadoresOG(response.data.data); setLoading(false) })
      .catch((err) => { console.log(err) })
  }

  function getSuscripcionesActivas() {
    for (let i in donadores) {
      axios.get(`${process.env.REACT_APP_BackURL}/getAllSuscripciones`)
        .then((response) => {
          let cuantas = 0

          for (let i2 in response.data.data) {
            if (response.data.data[i2].donadorID._id == donadores[i]._id) {
              cuantas++
            }

            if (response.data.data.length == Number(i2) + 1) {
              donadores[i].cuantas = cuantas
              setA(Math.random())
            }
          }
        })
        .catch((err) => { console.log(err) })
    }
  }

  // function desactivar(id) {
  //   axios.patch(`${process.env.REACT_APP_BackURL}/updateDonador?id=${id}`, { activo: false })
  //     .then(() => { setVisibleAlert1(true); getDonadores() })
  //     .catch((err) => { setVisibleAlert3(true) })
  // }

  // function activar(id) {
  //   axios.patch(`${process.env.REACT_APP_BackURL}/updateDonador?id=${id}`, { activo: true })
  //     .then(() => { setVisibleAlert2(true); getDonadores() })
  //     .catch((err) => { setVisibleAlert3(true) })
  // }

  function actualiza(id) {
    let datosActualizar = {
      nombreCompleto: nombreCompleto,
      cel: cel,
      mail: mail,
      nombres: nombres
    }

    axios.patch(`${process.env.REACT_APP_BackURL}/updateDonador?id=${id}`, datosActualizar)
      .then(() => { setVisibleAlert4(true); getDonadores() })
      .catch((err) => { setVisibleAlert3(true) })
  }

  function exportarExcel() {
    let datos = []
    let nombreCompleto = donadores.map((v) => v.nombreCompleto)
    let cel = donadores.map((v) => v.cel)
    let mail = donadores.map((v) => v.mail)
    let suscripcionesActivas = donadores.map((v) => v.cuantas)

    for (var i = 0; i < nombreCompleto.length; i++) {
      datos.push({
        'Nombre Completo': nombreCompleto[i],
        'Celular': cel[i],
        'Mail': mail[i],
        'Suscripciones Activas': suscripcionesActivas[i],
      })
    }

    exportFromJSON({ data: datos, fileName: "Reporte_Donadores", exportType: "csv" })
  }

  // handle change event of search input
  const handleChange = (value) => {
    filterData(value)
  };

  // // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setDonadores(donadoresOG);
    else {
      const filteredData = donadoresOG.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setDonadores(filteredData);
    }
  };

  useLayoutEffect(() => {
    getDonadores()
  }, [])

  useEffect(() => {
    if (donadores.length > 0) { getSuscripcionesActivas() }
  }, [donadores])

  useEffect(() => {
    if (acabo) {
      setNombreCompleto(datos.nombreCompleto)
      setCel(datos.cel)
      setMail(datos.mail)

      for (let i in datos.nombres) {
        let temp = []

        temp.push(datos.nombres[i])

        if (Number(i) + 1 == datos.nombres.length) { setNombres(temp) }
      }
    }

  }, [acabo])

  useEffect(() => {
    if (visibleAlert1) { setTimeout(() => { setVisibleAlert1(false) }, 5000); }
    if (visibleAlert2) { setTimeout(() => { setVisibleAlert2(false) }, 5000); }
    if (visibleAlert3) { setTimeout(() => { setVisibleAlert3(false) }, 5000); }
    if (visibleAlert4) { setTimeout(() => { setVisibleAlert4(false) }, 5000); }
  }, [visibleAlert1, visibleAlert2, visibleAlert3, visibleAlert4])

  return (
    <>
      <div className='centro'>
        <CAlert color="warning" dismissible visible={visibleAlert1} onClose={() => { getDonadores(); setVisibleAlert1(false) }}>Subscripcion Anulada!</CAlert>
        <CAlert color="primary" dismissible visible={visibleAlert2} onClose={() => { getDonadores(); setVisibleAlert2(false) }}>Subscripcion Reactivada!</CAlert>
        <CAlert color="danger" dismissible visible={visibleAlert3} onClose={() => { setVisibleAlert3(false) }}>Ocurio un error, intenta de nuevo por favor.</CAlert>
        <CAlert color="primary" dismissible visible={visibleAlert4} onClose={() => { getDonadores(); setVisibleAlert4(false) }}>Datos actualizados!</CAlert>

        <CModal visible={visible1} onClose={() => setVisible1(false)}>
          <CModalHeader onClose={() => setVisible1(false)}>
            <CModalTitle>Nombres</CModalTitle>
          </CModalHeader>

          <CModalBody>
            <CTable responsive color="dark" hover>
              <CTableHead>
                <CTableRow color='info'>
                  <CTableHeaderCell>Nombre</CTableHeaderCell>
                  <CTableHeaderCell>Categoria</CTableHeaderCell>
                  <CTableHeaderCell>Anonimo</CTableHeaderCell>
                </CTableRow>
              </CTableHead>

              <CTableBody>
                {
                  nombresDisplay.map((item) => {
                    return (
                      <>
                        <CTableRow>
                          <CTableDataCell>{item.nombre}</CTableDataCell>
                          <CTableDataCell>{item.categoria}</CTableDataCell>
                          <CTableDataCell>{!item.anonimo ? 'No' : 'Si'}</CTableDataCell>
                        </CTableRow>
                      </>
                    )
                  })
                }
              </CTableBody>
            </CTable>
          </CModalBody>

          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible1(false)}>Cerrar</CButton>
          </CModalFooter>
        </CModal>

        <CModal visible={visible2} onClose={() => { setVisible2(false); setAcabo(false) }}>
          <CModalHeader onClose={() => setVisible2(false)}>
            <CModalTitle>Datos del donador</CModalTitle>
          </CModalHeader>

          <CModalBody>
            {
              visible2 ?
                <>
                  <CFormInput type="text" id="floatingInput" floatingLabel="Nombre Completo" placeholder='' onChange={(e) => setNombreCompleto(e.target.value)} value={nombreCompleto} />

                  <br />

                  <CFormInput type="number" id="floatingInput" floatingLabel="Celular" placeholder='' onChange={(e) => setCel(e.target.value)} value={cel} />

                  <br />

                  <CFormInput type="mail" id="floatingInput" floatingLabel="Mail" placeholder='' onChange={(e) => setMail(e.target.value)} value={mail} />

                  <br />

                  <div className='nombres'>
                    {
                      datos.nombres.map((item, index) => {
                        return (
                          <>
                            <CFormInput type="text" id="floatingInput" floatingLabel={`Nombre ${index + 1}`} placeholder='' value={item.nombre} onChange={(e) => { item.nombre = e.target.value; setA(Math.random()) }} />

                            <CFormSelect id="floatingSelect" floatingLabel="Categoria" aria-label="Floating label select example" onChange={(e) => { item.categoria = e.target.value }}
                              value={item.categoria == 'beraja' ? 'beraja' : item.categoria == 'refua' ? 'refua' : item.categoria == 'parnasa' ? 'parnasa' : item.categoria == 'zivug' ? 'zivug' : item.categoria == 'hijos' ? 'hijos' : item.categoria == 'leiluy' ? 'leiluy' : 'beraja'} >
                              <option value="beraja">Beraja y hatzlaja</option>
                              <option value="refua">Refua shelema</option>
                              <option value="parnasa">Parnasa</option>
                              <option value="zivug">Zivug</option>
                              <option value="hijos">Hijos</option>
                              <option value="leiluy">Leiluy Nishmat</option>
                            </CFormSelect>

                            <CFormSwitch size="xl" label="Anonimo" defaultChecked={item.anonimo} onChange={(e) => { item.anonimo = e.target.checked }} />

                            {index + 1 == datos.nombres.length ? <></> : <><br /></>}
                          </>
                        )
                      })
                    }
                  </div>

                  <br />

                  {/* <CFormInput type="number" id="floatingInput" floatingLabel="Cantidad Mensual" placeholder='' value={datos.cantidad} /> */}

                  <h4>Cantidad: {datos.cantidad}</h4>
                  <br />
                  <h4>Abrej: {datos.abrejID.nombre}</h4>

                  {/* <CFormInput type="text" id="floatingInput" floatingLabel="Abrej q vendio" placeholder='' value={'ss'} /> */}

                  <br />

                </>
                : null
            }
          </CModalBody>

          <CModalFooter>
            <CButton color="primary" onClick={() => { setVisible2(false); actualiza(datos._id) }}>Actualizar</CButton>

            <CButton color="secondary" onClick={() => { setVisible2(false); setAcabo(false) }}>Cerrar</CButton>
          </CModalFooter>
        </CModal>
        <center>
          <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { handleChange(e.target.value) })} />
        </center>

        <br />

        {
          !loading ?
            <>
              <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
                <CTableHead>
                  <CTableRow color='info'>
                    <CTableHeaderCell>Donador</CTableHeaderCell>
                    <CTableHeaderCell>Celular</CTableHeaderCell>
                    <CTableHeaderCell>Mail</CTableHeaderCell>
                    <CTableHeaderCell>Suscripciones Activas</CTableHeaderCell>
                    <CTableHeaderCell>Ya dono</CTableHeaderCell>
                    <CTableHeaderCell><CButton color="success" shape="rounded-pill" onClick={exportarExcel}><i className="far fa-file-excel"></i> XLS</CButton></CTableHeaderCell>
                    {/* <CTableHeaderCell>Nombres</CTableHeaderCell>
                    <CTableHeaderCell></CTableHeaderCell> */}
                    {/* <CTableHeaderCell></CTableHeaderCell> */}
                  </CTableRow>
                </CTableHead>

                <CTableBody>
                  {
                    donadores.length > 0 ?
                      <>
                        {
                          donadores.map((item) => {
                            return (
                              <>
                                <CTableRow>
                                  <CTableDataCell>{item.nombreCompleto}</CTableDataCell>
                                  <CTableDataCell>{item.cel}</CTableDataCell>
                                  <CTableDataCell>{item.mail}</CTableDataCell>
                                  <CTableDataCell>{item.cuantas}</CTableDataCell>
                                  <CTableDataCell>{item.yaDono ? "Si": "No"}</CTableDataCell>
                                  <CTableDataCell></CTableDataCell>
                                  {/* <CTableDataCell><CButton color='info' shape="rounded-pill" onClick={() => { setVisible1(true); setNombresDisplay(item.nombres) }}><CIcon icon={cilAddressBook} size="xl" /></CButton></CTableDataCell>
                          <CTableDataCell><CButton color='success' shape="rounded-pill" onClick={() => { setVisible2(true); setDatos(item); setAcabo(true) }}>Editar</CButton></CTableDataCell> */}
                                  {/* {
                            item.activo
                              ?
                              <><CTableDataCell><CButton color='danger' shape="rounded-pill" onClick={() => { desactivar(item._id) }}>Eliminar Subscripcion</CButton></CTableDataCell></>
                              :
                              <><CTableDataCell><CButton color='success' shape="rounded-pill" onClick={() => { activar(item._id) }}>Reanudar Subscripcion</CButton></CTableDataCell></>
                          } */}
                                </CTableRow>
                              </>
                            )
                          })
                        }
                      </>
                      :
                      <>
                        <CTableRow>
                          <CTableDataCell colSpan="7">Sin Resultados</CTableDataCell>
                        </CTableRow>
                      </>
                  }

                </CTableBody>
              </CTable>
            </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }
      </div>
    </>
  )
}

export default Donadores
