import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import Routes from './routes/Routes'
import { Provider } from 'react-redux'
import store from './store'

import './fonts/HVD Fonts - BrandonGrotesque-Black.otf';
import './fonts/HVD Fonts - BrandonGrotesque-Bold.otf';
import './fonts/HVD Fonts - BrandonGrotesque-BoldItalic.otf';

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    {/* <App /> */}
    <Routes />
  </Provider>,
)