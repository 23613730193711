import React, { useEffect } from 'react'
import { CButton } from '@coreui/react'
import { loadStripe } from "@stripe/stripe-js";
import Swal from 'sweetalert2';

function CheckoutStripeUnico(props) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

  //---------------All this its Stripe config-------------------------------------------------------------
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      Swal.fire('Gracias por tu compra')
      window.location.href = "/gracias"
    }
    if (query.get("canceled")) {
      Swal.fire('Compra Cancelada')
      window.location.href = "/"
    }
  }, []);

  const handleClick = async () => {
    const stripe = await stripePromise

    const response = await fetch(`${process.env.REACT_APP_BackURL}/sesionStripeUnico`, {
      method: "POST",
      headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}`, Accept: "application/json", "Content-Type": "application/json", },

      body: JSON.stringify({
        amount: 180000,
        quantity: 1,
        item: "Tehilim por dia único",
        metadata: {
          nombreCompleto: props.nombreCompletoUnico,
          mail: props.emailUnico,
          cel: props.celUnico,
          nombres: props.nombresUnico,
          codigoFamilia: props.codigoFamilia,
          fecha: props.fechaUnico
        },
      }),
    })

    const session = await response.json()

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    })

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      console.log(result.error)
    }
  };

  //------------------------------------------------------------------------------------------------------
  return (
    <center>
      <CButton onClick={() => handleClick()} className='btnStripe' color='warning'>Pagar con tarjeta</CButton>
    </center>
  )
}

export default CheckoutStripeUnico