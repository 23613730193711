import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs'
import './Dashboard.scss'
import './Dashboard.css'

const Dashboard = () => {
  let [contadorTehilim, setContadorTehilim] = useState()
  let [contadorPerakim, setContadorPerakim] = useState()
  let [donadoresRegistrados, setDonadoresRegistrados] = useState()
  let [donadoresSucripcion, setDonadoresSuscripcion] = useState()
  let [vendedoresActivos, setVendedoresActivos] = useState()
  let [vendedoresAceptar, setVendedoresAceptar] = useState()
  let [suscripcionesActivas, setSuscripcionesActivas] = useState()
  let [donativosDia, setDonativosDia] = useState()
  let [ingresoSuscripcionesMes, setIngresoSuscripcionesMes] = useState()
  let [ingresoSuscripcionesMesTotal, setIngresoSuscripcionesMesTotal] = useState()
  let [ingresoDonativoDiaMes, setIngresoDonativoDiaMes] = useState()
  let [giftsDisponibles, setGiftsDisponibles] = useState()
  let [suscripcionesGratisActivas, setSuscripcionesGratisActivas] = useState()
  let meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']

  useLayoutEffect(() => {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllPerakim`)
      .then((response) => { setContadorPerakim(response.data.data[0].contadorPerakim); setContadorTehilim(response.data.data[0].contadorTehilim) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllSuscripciones`)
      .then((response) => {

        let ingresoSuscripciones = 0

        for (let i = 0; i < response.data.data.length; i++) {
          // console.log(Number(dayjs().diff(dayjs(response.data.data[i].createdAt), 'month')) + 1, "MESES", response.data.data[i].cantidad, "PESOS", response.data.data[i].donadorID.nombreCompleto, "TOTAL DONADO", response.data.data[i].cantidad * (Number(dayjs().diff(dayjs(response.data.data[i].createdAt), 'month')) + 1))
          ingresoSuscripciones += response.data.data[i].cantidad * (Number(dayjs().diff(dayjs(response.data.data[i].createdAt), 'month')) + 1)
        }

        setIngresoSuscripcionesMesTotal(ingresoSuscripciones);
        setSuscripcionesActivas(response.data.data.length);
        setDonadoresSuscripcion(response.data.data.length);
        setIngresoSuscripcionesMes(response.data.data.reduce((a, b) => a + b.cantidad, 0))
      })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllDonadores`)
      .then((response) => setDonadoresRegistrados(response.data.data.length))
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllPagosUnicos`)
      .then((response) => { setDonativosDia(response.data.data.reduce((a, b) => a + b.cantidad, 0)); setIngresoDonativoDiaMes(response.data.data.filter((donativo) => dayjs(donativo.createdAt).get('month') == dayjs().get('month')).reduce((a, b) => a + b.cantidad, 0)) })

      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllAbrejim`)
      .then((response) => { setVendedoresActivos(response.data.data.filter((vendedor) => vendedor.activo && !vendedor.rechazado).length - 1); setVendedoresAceptar(response.data.data.filter((vendedor) => !vendedor.activo && !vendedor.rechazado).length) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllGifts`)
      .then((response) => { setGiftsDisponibles(response.data.data.filter((gift) => gift.is_active).length)})
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllSuscripcionesGratis`)
      .then((response) => {setSuscripcionesGratisActivas(response.data.data.length)} )
      .catch((err) => { console.log(err) })

  }, [])

  function cambio(cual, event) {
    axios.patch(`${process.env.REACT_APP_BackURL}/updatePerek?id=6335ebbf5e81601884f0b7cc`, { [cual]: event })
      .then((response) => { console.log(response.data.message) })
      .catch((err) => { console.log(err) })
  }

  return (
    <>
      <br />

      <center>
        <h3>Dashboard</h3>
      </center>

      <br /><br />

      <div className="row">
        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/donadores">
            <div style={{ maxHeight: "140px" }} className="card l-bg-cherry">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-money-bill-wave"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Donadores registrados</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{donadoresRegistrados} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/suscripciones">
            <div style={{ maxHeight: "140px" }} className="card l-bg-red-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-hand-holding-dollar"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Donadores con suscripcion</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{donadoresSucripcion} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/vendedores">
            <div style={{ maxHeight: "140px" }} className="card l-bg-blue-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-users" />
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Vendedores activos</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{vendedoresActivos} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/vendedores">
            <div style={{ maxHeight: "140px" }} className="card l-bg-yellow-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-user-plus" />
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Vendedores por aceptar</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{vendedoresAceptar} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/suscripciones">
            <div style={{ maxHeight: "140px" }} className="card l-bg-green-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-chart-line"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Suscripciones activas</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{suscripcionesActivas} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/unicos">
            <div style={{ maxHeight: "140px" }} className="card l-bg-rojo-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-money-bill-trend-up"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Suscripciones gratis activas</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">

                    <h2 className="d-flex align-items-center mb-0">{suscripcionesGratisActivas}</h2>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/unicos">
            <div style={{ maxHeight: "140px" }} className="card l-bg-amarillo2-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-money-bill-trend-up"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Gifts disponibles</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">

                    <h2 className="d-flex align-items-center mb-0">{giftsDisponibles}</h2>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/suscripciones">
            <div style={{ maxHeight: "140px" }} className="card l-bg-orange-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-sack-dollar"></i>{" "}
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Ingreso suscripciones por mes</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">

                    <h2 className="d-flex align-items-center mb-0">
                      ${ingresoSuscripcionesMes} &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/unicos">
            <div style={{ maxHeight: "140px" }} className="card l-bg-purple-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-money-bill-trend-up"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Ingreso {meses[dayjs().get('month')]} donativos 1 dia</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">

                    <h2 className="d-flex align-items-center mb-0">${ingresoDonativoDiaMes} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/unicos">
            <div style={{ maxHeight: "140px" }} className="card l-bg-aqua-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-coins"></i>{" "}
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Ingreso total donativos 1 dia</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">${donativosDia} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>        

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/unicos">
            <div style={{ maxHeight: "140px" }} className="card l-bg-amarillo-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-money-bill-trend-up"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Ingreso total de suscripciones</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">

                    <h2 className="d-flex align-items-center mb-0">${ingresoSuscripcionesMesTotal} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/unicos">
            <div style={{ maxHeight: "140px" }} className="card l-bg-azul-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-money-bill-trend-up"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Ingreso total del proyecto</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">

                    <h2 className="d-flex align-items-center mb-0">${ingresoSuscripcionesMesTotal + donativosDia} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>       

      </div>

      <center>
        <br />

        <h4>Contador de cadenas de Tehilim</h4>

        <input onClick={() => { !contadorTehilim ? (setContadorTehilim(true), cambio("contadorTehilim", true)) : (setContadorTehilim(false), cambio("contadorTehilim", false)) }} checked={contadorTehilim ? true : false} className="tgl tgl-skewed" id="cb3" type="checkbox" />
        <label className="tgl-btn" data-tg-off="OFF" data-tg-on="ON" for="cb3"></label>
      </center>

      <br />

      <center>
        <br />

        <h4>Contador de perakim diarios</h4>

        <input onClick={() => { !contadorPerakim ? (setContadorPerakim(true), cambio("contadorPerakim", true)) : (setContadorPerakim(false), cambio("contadorPerakim", false)) }} checked={contadorPerakim ? true : false} className="tgl tgl-skewed" id="cb4" type="checkbox" />
        <label className="tgl-btn" data-tg-off="OFF" data-tg-on="ON" for="cb4"></label>
      </center>

      <br /><br />
    </>
  )
}

export default Dashboard
