import React, { useLayoutEffect, useState } from 'react'
import axios from 'axios'
import ReactLoading from 'react-loading'
import { Carousel, Table } from 'react-bootstrap'

function ListaNombres(props) {
    let [loading, setLoading] = useState(true)
    let [listaBeraja, setListaBeraja] = useState([])
    let [listaRefua, setListaRefua] = useState([])
    let [listaParnasa, setListaParnasa] = useState([])
    let [listaZivug, setListaZivug] = useState([])
    let [listaZera, setListaZera] = useState([])
    let [listaLeiluy, setListaLeiluy] = useState([])
    let [listaAgradecimiento, setListaAgradecimiento] = useState([])
    let [paginationBeraja, setPaginationBeraja] = useState(0)
    let [paginationRefua, setPaginationRefua] = useState(0)
    let [paginationParnasa, setPaginationParnasa] = useState(0)
    let [paginationZivug, setPaginationZivug] = useState(0)
    let [paginationZera, setPaginationZera] = useState(0)
    let [paginationLeiluy, setPaginationLeiluy] = useState(0)
    let [paginationAgradecimiento, setPaginationAgradecimiento] = useState(0)
    // let cabenPantalla = 17
    // 42 nombres caben en la pantalla ajustar dependiendo de las pantallas este numero
    let cabenPantalla = props.cantidadNombres

    function getData() {
        axios.get(`${process.env.REACT_APP_BackURL}/getAllSuscripcionesGratis`)
            .then((response1) => {

                axios.get(`${process.env.REACT_APP_BackURL}/getAllSuscripciones`)
                    .then((response2) => {

                        let data = response1.data.data.filter((item) => { return item.active }).concat(response2.data.data)

                        let tempBeraja = []
                        let tempRefua = []
                        let tempParnasa = []
                        let tempZivug = []
                        let tempZera = []
                        let tempLeiluy = []
                        let tempAgradecimiento = []

                        let tempPagBeraja = []
                        let tempPagRefua = []
                        let tempPagParnasa = []
                        let tempPagZivug = []
                        let tempPagZera = []
                        let tempPagLeiluy = []
                        let tempPagAgradecimiento = []

                        // este for es para probar pagination

                        // for (let i = 0; i < 160; i++) {
                        //     tempBeraja.push("Reuben ben Sara Linda")
                        //     tempZivug.push("Reuben ben Sara Linda")
                        // }

                        data.map((item, index) => {
                            for (let i in item.nombres) {
                                if (!item.nombres[i].anonimo) {
                                    if (item.nombres[i].categoria == "Beraja y Hatzlaja") { tempBeraja.push(item.nombres[i].nombre) }
                                    if (item.nombres[i].categoria == "Refua Shelema") { tempRefua.push(item.nombres[i].nombre) }
                                    if (item.nombres[i].categoria == "Parnasa Toba") { tempParnasa.push(item.nombres[i].nombre) }
                                    if (item.nombres[i].categoria == "Zivug Hagun") { tempZivug.push(item.nombres[i].nombre) }
                                    if (item.nombres[i].categoria == "Zera Shel Kayama") { tempZera.push(item.nombres[i].nombre) }
                                    if (item.nombres[i].categoria == "Leiluy Nishmat") { tempLeiluy.push(item.nombres[i].nombre) }
                                    if (item.nombres[i].categoria == "Agradecimiento") { tempAgradecimiento.push(item.nombres[i].nombre) }
                                }
                            }

                            if (data.length - 1 == index) {

                                for (let i = 0; i <= ~~(tempBeraja.length / cabenPantalla); i++) {
                                    tempPagBeraja.push(i)
                                    if (i + 1 == tempPagBeraja.length) { setPaginationBeraja(tempPagBeraja) }
                                }

                                for (let i = 0; i <= ~~(tempRefua.length / cabenPantalla); i++) {
                                    tempPagRefua.push(i)
                                    if (i + 1 == tempPagRefua.length) { setPaginationRefua(tempPagRefua) }
                                }

                                for (let i = 0; i <= ~~(tempParnasa.length / cabenPantalla); i++) {
                                    tempPagParnasa.push(i)
                                    if (i + 1 == tempPagParnasa.length) { setPaginationParnasa(tempPagParnasa) }
                                }

                                for (let i = 0; i <= ~~(tempZivug.length / cabenPantalla); i++) {
                                    tempPagZivug.push(i)
                                    if (i + 1 == tempPagZivug.length) { setPaginationZivug(tempPagZivug) }
                                }

                                for (let i = 0; i <= ~~(tempZera.length / cabenPantalla); i++) {
                                    tempPagZera.push(i)
                                    if (i + 1 == tempPagZera.length) { setPaginationZera(tempPagZera) }
                                }

                                for (let i = 0; i <= ~~(tempLeiluy.length / cabenPantalla); i++) {
                                    tempPagLeiluy.push(i)
                                    if (i + 1 == tempPagLeiluy.length) { setPaginationLeiluy(tempPagLeiluy) }
                                }

                                for (let i = 0; i <= ~~(tempAgradecimiento.length / cabenPantalla); i++) {
                                    tempPagAgradecimiento.push(i)
                                    if (i + 1 == tempPagAgradecimiento.length) { setPaginationAgradecimiento(tempPagAgradecimiento) }
                                }

                                setLoading(false)
                                setListaBeraja(tempBeraja)
                                setListaRefua(tempRefua)
                                setListaParnasa(tempParnasa)
                                setListaZivug(tempZivug)
                                setListaZera(tempZera)
                                setListaLeiluy(tempLeiluy)
                                setListaAgradecimiento(tempAgradecimiento)
                            }
                        })
                    })

                    .catch((err) => { console.log(err) })
            })
            .catch((error) => { console.log(error) })
    }

    useLayoutEffect(() => {
        getData()
        
        setInterval(() => { getData() }, 60000 * 60)
    }, [])

    return (
        <>
            {
                !loading ?
                    <>
                        <Carousel controls={true} interval={15000}>
                            {
                                listaBeraja.length > 0 ?
                                    paginationBeraja.map((item, index) => {
                                        return (
                                            <Carousel.Item>
                                                <p className='titulo'>Beraja y Hatzlaja</p>
                                                <div className='listaNombres'>
                                                    {
                                                        listaBeraja.map((item2, index2) => {
                                                            if (index2 >= cabenPantalla * index && index2 < cabenPantalla * (index + 1)) {
                                                                return (<p>{listaBeraja[index2]}</p>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })

                                    : null
                            }

                            {
                                listaRefua.length > 0 ?
                                    paginationRefua.map((item, index) => {
                                        return (
                                            <Carousel.Item>
                                                <p className='titulo'>Refua Shelema</p>
                                                <div className='listaNombres'>
                                                    <p>Aaron Meir Ben Sara</p>
                                                    <p>Eliahu Michel Ben Sylvia</p>
                                                    <p>Alicia Bat Rebecca</p>
                                                    {
                                                        listaRefua.map((item2, index2) => {
                                                            if (index2 >= cabenPantalla * index && index2 < cabenPantalla * (index + 1)) {
                                                                return (<p>{listaRefua[index2]}</p>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })

                                    : null
                            }

                            {
                                listaParnasa.length > 0 ?
                                    paginationParnasa.map((item, index) => {
                                        return (
                                            <Carousel.Item>
                                                <p className='titulo'>Parnasa Toba</p>
                                                <div className='listaNombres'>
                                                    {
                                                        listaParnasa.map((item2, index2) => {
                                                            if (index2 >= cabenPantalla * index && index2 < cabenPantalla * (index + 1)) {
                                                                return (<p>{listaParnasa[index2]}</p>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })

                                    : null
                            }

                            {
                                listaZivug.length > 0 ?
                                    paginationZivug.map((item, index) => {
                                        return (
                                            <Carousel.Item>
                                                <p className='titulo'>Zivug Hagun</p>
                                                <div className='listaNombres'>
                                                    {
                                                        listaZivug.map((item2, index2) => {
                                                            if (index2 >= cabenPantalla * index && index2 < cabenPantalla * (index + 1)) {
                                                                return (<p>{listaZivug[index2]}</p>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })

                                    : null
                            }

                            {
                                listaZera.length > 0 ?
                                    paginationZera.map((item, index) => {
                                        return (
                                            <Carousel.Item>
                                                <p className='titulo'>Zera Shel Kayama</p>
                                                <div className='listaNombres'>
                                                    {
                                                        listaZera.map((item2, index2) => {
                                                            if (index2 >= cabenPantalla * index && index2 < cabenPantalla * (index + 1)) {
                                                                return (<p>{listaZera[index2]}</p>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })

                                    : null
                            }

                            {
                                listaLeiluy.length > 0 ?
                                    paginationLeiluy.map((item, index) => {
                                        return (
                                            <Carousel.Item>
                                                <p className='titulo'>Leiluy Nishmat</p>
                                                <div className='listaNombres'>
                                                    <p>Elvira Bat Adela</p>
                                                    {
                                                        listaLeiluy.map((item2, index2) => {
                                                            if (index2 >= cabenPantalla * index && index2 < cabenPantalla * (index + 1)) {
                                                                return (<p>{listaLeiluy[index2]}</p>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })

                                    : null
                            }

                            {
                                listaAgradecimiento.length > 0 ?
                                    paginationAgradecimiento.map((item, index) => {
                                        return (
                                            <Carousel.Item>
                                                <p className='titulo'>Agradecimiento</p>
                                                <div className='listaNombres'>
                                                    {
                                                        listaAgradecimiento.map((item2, index2) => {
                                                            if (index2 >= cabenPantalla * index && index2 < cabenPantalla * (index + 1)) {
                                                                return (<p>{listaAgradecimiento[index2]}</p>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })

                                    : null
                            }

                        </Carousel>
                    </>

                    : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
            }
        </>
    )
}

export default ListaNombres