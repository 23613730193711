import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";
import { CButton, CModal, CFormInput, CModalHeader, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CForm, CFormLabel, CFormSwitch, CFormSelect, CModalTitle, CAlert, CModalBody, CModalFooter, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import { cilAddressBook } from '@coreui/icons';
import { isMobile, BrowserView, MobileView, } from 'react-device-detect';
import Swal from "sweetalert2";
import CIcon from '@coreui/icons-react'
import axios from 'axios'
import base64 from 'base-64';
import ReactLoading from 'react-loading'
import Logo from '../assets/logo.png'
import LogoYeshiba from '../assets/logoYeshiba.png'
import logoActive from "../assets/LogoActiveAnimated.gif";
import mario from '../assets/mario.png'
import useSound from "use-sound";
import marioJump from "../sounds/marioJump.mp3";
// import { useNavigate } from 'react-router-dom';
import './AdminDonador.css'


function AdminDonador(props) {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)

  const [play] = useSound(marioJump);
  let [count, setCount] = useState(1)

  let [loading, setLoading] = useState(false)
  let [listo, setListo] = useState(true)
  let [error, setError] = useState(false)
  let [timeOut, setTimeOut] = useState(false)
  let [datos, setDatos] = useState(undefined)
  let [email, setEmail] = useState(undefined)
  let [emailVacio2, setEmailVacio2] = useState()

  let [nombreDonador, setNombreDonador] = useState(undefined)
  let [cual, setCual] = useState(undefined)

  let [cuantos, setCuantos] = useState(0)
  let [subscriptionID, setSubscriptionID] = useState(undefined)
  let [donadorID, setDonadorID] = useState(undefined)

  let [nombre1, setNombre1] = useState('')
  let [nombre2, setNombre2] = useState('')
  let [nombre3, setNombre3] = useState('')
  let [nombre4, setNombre4] = useState('')
  let [nombre5, setNombre5] = useState('')
  let [nombre1Vacio, setNombre1Vacio] = useState()
  let [nombre2Vacio, setNombre2Vacio] = useState()
  let [nombre3Vacio, setNombre3Vacio] = useState()
  let [nombre4Vacio, setNombre4Vacio] = useState()
  let [nombre5Vacio, setNombre5Vacio] = useState()

  let [categoria1, setCategoria1] = useState('Selecciona')
  let [categoria2, setCategoria2] = useState('Selecciona')
  let [categoria3, setCategoria3] = useState('Selecciona')
  let [categoria4, setCategoria4] = useState('Selecciona')
  let [categoria5, setCategoria5] = useState('Selecciona')

  let [anonimo1, setAnonimo1] = useState(false)
  let [anonimo2, setAnonimo2] = useState(false)
  let [anonimo3, setAnonimo3] = useState(false)
  let [anonimo4, setAnonimo4] = useState(false)
  let [anonimo5, setAnonimo5] = useState(false)

  let [nombreCompleto, setNombreCompleto] = useState()
  let [cel, setCel] = useState()
  let [mail, setMail] = useState()

  let [nombreCompletoVacio, setNombreCompletoVacio] = useState()
  let [mailVacio, setMailVacio] = useState()
  let [celVacio, setCelVacio] = useState()

  let [widthLogos, setWidthLogos] = useState('8%')

  // const navigate = useNavigate();

  function logoHandler() {
    let temp = count

    if (count == 14) {
      play()
      document.getElementById('mario').classList.add("mario")
      setTimeout(() => { document.getElementById('mario').classList.remove("mario") }, 600);
      setCount(1)
    }
    else { temp++; setCount(temp) }
  }

  function handleSubmit() {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {

      if (email == undefined || email == '') { setEmailVacio2("vacio") }
      Swal.fire('Por favor ingresa tu correo para continuar')
    }

    else {
      setLoading(true)

      axios.get(`${process.env.REACT_APP_BackURL}/getAllDonadores`)
        .then((response) => {
          let exist = false
          let tieneSuscripciones = false
          let donadorID

          axios.get(`${process.env.REACT_APP_BackURL}/getAllSuscripciones`)
            .then((response2) => {

              for (let i in response.data.data) { if (response.data.data[i].mail == email) { exist = true; donadorID = response.data.data[i]._id } }

              for (let i in response2.data.data) {
                if (response2.data.data[i].donadorID._id == donadorID && !response2.data.data[i].PaypalWillDelete) {
                  tieneSuscripciones = true
                }
              }

              if (exist && tieneSuscripciones) {
                axios.post(`${process.env.REACT_APP_BackURL}/requestChange`, { mail: email })
                  .then(() => {
                    setEmail(''); setLoading(false); setError(false); setTimeout(false)
                    Swal.fire('Gracias, recibiras un correo con instrucciones para modificar tu suscripcion')
                  })
                  .catch((err) => { setLoading(false); Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })
              }

              else {
                setLoading(false)
                if (!exist) { Swal.fire('Este correo no esta registrado, por favor intenta de nuevo') }
                else if (!tieneSuscripciones) { Swal.fire('Este correo no tiene suscripciones activas') }
              }

            })
            .catch((err) => { setLoading(false); Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })
        })

        .catch((err) => { setLoading(false); Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })
    }
  }

  function actualizaNombres() {
    if (nombre1Vacio == 'vacio' || nombre2Vacio == 'vacio' || nombre3Vacio == 'vacio' || nombre4Vacio == 'vacio' || nombre5Vacio == 'vacio') {
      Swal.fire('Los nombres no pueden estar en blanco, por favor llena todos los campos')
    }

    else {
      let nombresTemp = []

      if (cuantos > 0) { nombresTemp[0] = { nombre: nombre1, categoria: categoria1, anonimo: anonimo1 } }
      if (cuantos > 2) { nombresTemp[1] = { nombre: nombre2, categoria: categoria2, anonimo: anonimo2 }; nombresTemp[2] = { nombre: nombre3, categoria: categoria3, anonimo: anonimo3 } }
      if (cuantos > 4) { nombresTemp[3] = { nombre: nombre4, categoria: categoria4, anonimo: anonimo4 }; nombresTemp[4] = { nombre: nombre5, categoria: categoria5, anonimo: anonimo5 } }

      axios.patch(`${process.env.REACT_APP_BackURL}/updateSubscription?id=${subscriptionID}`, { nombres: nombresTemp })
        .then(() => { setVisible2(false); getSuscripciones() })
        .catch((err) => { Swal.fire('Ocurrio un error, intenta de nuevo por favor.'); setVisible2(false); limpia() })
    }
  }

  function actualizaDonador() {
    if (nombreCompletoVacio == 'vacio' || celVacio == 'vacio' || mailVacio == 'vacio') {
      Swal.fire('Los datos estan incompletos, por favor llena todos los campos')
    }

    else {
      let data = { nombreCompleto: nombreCompleto, cel: cel, mail: mail }

      axios.patch(`${process.env.REACT_APP_BackURL}/updateDonador?id=${donadorID}`, data)
        .then(() => { setVisible1(false); getSuscripciones() })
        .catch((err) => { Swal.fire('Ocurrio un error, intenta de nuevo por favor.'); setVisible1(false); limpia() })
    }
  }

  function limpia() {
    setCual(undefined); setCuantos(0)

    setNombre1(''); setNombre2(''); setNombre3(''); setNombre4(''); setNombre5('')

    setNombre1Vacio(); setNombre2Vacio(); setNombre3Vacio(); setNombre4Vacio(); setNombre5Vacio()
  }

  function limpia2() {
    setNombreCompletoVacio(); setCelVacio(); setMailVacio()
    setNombreCompleto(datos[0].donadorID.nombreCompleto); setCel(datos[0].donadorID.cel); setMail(datos[0].donadorID.mail)
  }

  function getSuscripciones() {
    setListo(false)

    let decoded = (JSON.parse(base64.decode(params.get("ZLDA"))))

    if (new Date().getTime() > decoded.time) { setTimeOut(true) /* ; setHayDatos(false)*/; setListo(true) }
    else {
      // setHayDatos(true)

      axios.post(`${process.env.REACT_APP_BackURL}/getAllSuscripciones2`, { mario: decoded.mario })
        .then((response) => {
          let temp = []

          for (let i in response.data.data) {
            if (response.data.data[i].donadorID.mail == decoded.mail) {
              if (!response.data.data[i].PaypalWillDelete) {
                temp.push(response.data.data[i])
              }
            }

            if (response.data.data.length == Number(i) + 1) { setDonadorID(response.data.data[i].donadorID._id); setDatos(temp); setListo(true) }
          }
        })
        .catch((err) => { setError(true); setListo(true) })
    }
  }

  function cancelarPaypal(id, cuantas) {
    Swal.fire({ icon: "warning", iconColor: 'red', title: "Espera", text: '¿Estas seguro de querer cancelar?', allowEscapeKey: true, showCancelButton: true, cancelButtonText: 'Cambie de opinion', cancelButtonColor: 'red', confirmButtonText: 'Si, quiero cancelar', confirmButtonColor: 'green' })
      .then((e) => {
        if (e.isConfirmed) {
          let qs = require('qs')
          let body = qs.stringify({ 'grant_type': 'client_credentials' })

          axios.post('https://api-m.paypal.com/v1/oauth2/token', body, { auth: { username: process.env.REACT_APP_PAYPAL_CLIENT_ID, password: process.env.REACT_APP_PAYPAL_CLIENT_SECRET } })
            .then((response) => {
              let token = response.data.access_token

              axios.post(`https://api-m.paypal.com/v1/billing/subscriptions/${id}/cancel`,
                { 'reason': 'Cancelacion por parte de usuario' },
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }
              )
                .then((res) => {
                  axios.patch(`${process.env.REACT_APP_BackURL}/SetPaypalWillDelete`, { suscripcionPaypalID: id })
                    .then(() => {
                      Swal.fire('Suscripcion cancelada.')
                        .then(() => {
                          if (cuantas > 1) { window.location.reload() }
                          else { window.location.replace('https://tehilatenu.ae.edu.mx') }
                        })
                    })
                    .catch((err) => { Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })
                })
                .catch((err) => { Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })
            })
            .catch((err) => { Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })
        }
      })
  }

  useLayoutEffect(() => {
    if (isMobile) { setWidthLogos('30vw') }
    if (params.get("ZLDA")) { getSuscripciones() }
  }, [])

  useEffect(() => {
    if (datos != undefined) { setNombreDonador(datos[0].donadorID.nombreCompleto) }
  }, [datos])

  useEffect(() => {
    if (cual != undefined) {
      setNombreCompleto(datos[cual].donadorID.nombreCompleto)
      setCel(datos[cual].donadorID.cel)
      setMail(datos[cual].donadorID.mail)

      // let temp = []
      // for (let i in datos[cual].nombres) {

      //   temp.push(datos[cual].nombres[i])

      //   if (Number(i) + 1 == datos[cual].nombres.length) { setNombres(temp) }
      // }

      setCuantos(datos[cual].nombres.length)

      if (datos[cual].nombres.length > 0) {
        setNombre1(datos[cual].nombres[0].nombre)
        setCategoria1(datos[cual].nombres[0].categoria)
        setAnonimo1(datos[cual].nombres[0].anonimo)
      }

      if (datos[cual].nombres.length > 1) {
        setNombre2(datos[cual].nombres[1].nombre)
        setCategoria2(datos[cual].nombres[1].categoria)
        setAnonimo2(datos[cual].nombres[1].anonimo)

        setNombre3(datos[cual].nombres[2].nombre)
        setCategoria3(datos[cual].nombres[2].categoria)
        setAnonimo3(datos[cual].nombres[2].anonimo)
      }

      if (datos[cual].nombres.length > 3) {
        setNombre4(datos[cual].nombres[3].nombre)
        setCategoria4(datos[cual].nombres[3].categoria)
        setAnonimo4(datos[cual].nombres[3].anonimo)

        setNombre5(datos[cual].nombres[4].nombre)
        setCategoria5(datos[cual].nombres[4].categoria)
        setAnonimo5(datos[cual].nombres[4].anonimo)
      }
    }
  }, [cual])

  return (
    <>
      <CModal visible={visible1} onClose={() => setVisible1(false)}>
        <CModalHeader onClose={() => setVisible1(false)}>
          <CModalTitle>Datos de Donador</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <CFormInput type="text" id="exampleFormControlInput1" label="Nombre Completo" placeholder="" aria-describedby="exampleFormControlInputHelpInline"
            value={nombreCompleto}
            style={nombreCompletoVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
            onChange={(e) => { setNombreCompleto(e.target.value); e.target.value != '' ? setNombreCompletoVacio() : setNombreCompletoVacio('vacio') }}
          />

          <CFormInput type="number" id="exampleFormControlInput1" label={celVacio === "vacio" ? "Celular (10 digitos)" : "Celular"} placeholder="" aria-describedby="exampleFormControlInputHelpInline"
            value={cel}
            style={celVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
            onChange={(e) => {
              setCel(e.target.value);

              e.target.value == "" ?
                setCelVacio("vacio")
                :
                e.target.value.length < 10 ? setCelVacio("vacio")
                  :
                  setCelVacio()
            }}
          />

          <CFormInput type="email" id="exampleFormControlInput1" label="Email" placeholder="" aria-describedby="exampleFormControlInputHelpInline"
            value={mail}
            style={mailVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
            onChange={(e) => {
              setMail(e.target.value)

              e.target.value == '' ?
                setMailVacio('vacio')
                :
                !/\S+@\S+\.\S+/.test(e.target.value) ?
                  setMailVacio('vacio')
                  :
                  setMailVacio()
            }}
          />
        </CModalBody>

        <CModalFooter>
          <CButton color="info" onClick={() => { actualizaDonador() }}>Guardar</CButton>
          <CButton color="danger" onClick={() => { setVisible1(false); limpia2() }}>Cancelar</CButton>
        </CModalFooter>
      </CModal>

      <CModal visible={visible2} onClose={() => setVisible2(false)}>
        <CModalHeader onClose={() => setVisible2(false)}>
          <CModalTitle>Nombres</CModalTitle>
        </CModalHeader>

        <CModalBody>
          {
            cual != undefined ?
              <>
                <div className='nombres'>
                  {
                    cuantos > 0 ?
                      <>
                        <div style={{ 'margin': 'auto', 'width': '80' }}>
                          <CFormInput type="text" id="floatingInput" floatingLabel={`Nombre 1`} placeholder='' value={nombre1}
                            style={nombre1Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                            onChange={(e) => { setNombre1(e.target.value); e.target.value != '' ? setNombre1Vacio() : setNombre1Vacio('vacio') }} />
                        </div>

                        <br />

                        <div className='categoria_anonimo'>

                          <div style={{ 'width': '70%' }}>
                            <CFormSelect id="floatingSelect" aria-label="Floating label select example" onChange={(e) => { setCategoria1(e.target.value) }}
                              value={categoria1 == 'Beraja y Hatzlaja' ? 'Beraja y Hatzlaja' : categoria1 == 'Refua Shelema' ? 'Refua Shelema' : categoria1 == 'Parnasa Toba' ? 'Parnasa Toba' : categoria1 == 'Zivug Hagun' ? 'Zivug Hagun' : categoria1 == 'Zera Shel Kayama' ? 'Zera Shel Kayama' : categoria1 == 'Leiluy Nishmat' ? 'Leiluy Nishmat' : 'Beraja y Hatzlaja'} >
                              <option value="Beraja y Hatzlaja">Beraja y Hatzlaja</option>
                              <option value="Refua Shelema">Refua Shelema</option>
                              <option value="Parnasa Toba">Parnasa Toba</option>
                              <option value="Zivug Hagun">Zivug Hagun</option>
                              <option value="Zera Shel Kayama">Zera Shel Kayama</option>
                              <option value="Leiluy Nishmat">Leiluy Nishmat</option>
                            </CFormSelect>
                          </div>

                          <CFormSwitch size="xl" label="Anonimo" defaultChecked={anonimo1} onChange={(e) => { setAnonimo1(e.target.checked) }} />

                        </div>

                        <br />
                      </>
                      : null
                  }

                  {
                    cuantos > 2 ?
                      <>
                        <br />

                        <div style={{ 'margin': 'auto', 'width': '80' }}>
                          <CFormInput type="text" id="floatingInput" floatingLabel={`Nombre 2`} placeholder='' value={nombre2}
                            style={nombre2Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                            onChange={(e) => { setNombre2(e.target.value); e.target.value != '' ? setNombre2Vacio() : setNombre2Vacio('vacio') }} />
                        </div>

                        <br />

                        <div className='categoria_anonimo'>

                          <div style={{ 'width': '70%' }}>
                            <CFormSelect id="floatingSelect" aria-label="Floating label select example" onChange={(e) => { setCategoria2(e.target.value) }}
                              value={categoria2 == 'Beraja y Hatzlaja' ? 'Beraja y Hatzlaja' : categoria2 == 'Refua Shelema' ? 'Refua Shelema' : categoria2 == 'Parnasa Toba' ? 'Parnasa Toba' : categoria2 == 'Zivug Hagun' ? 'Zivug Hagun' : categoria2 == 'Zera Shel Kayama' ? 'Zera Shel Kayama' : categoria2 == 'Leiluy Nishmat' ? 'Leiluy Nishmat' : 'Beraja y Hatzlaja'} >
                              <option value="Beraja y Hatzlaja">Beraja y Hatzlaja</option>
                              <option value="Refua Shelema">Refua Shelema</option>
                              <option value="Parnasa Toba">Parnasa Toba</option>
                              <option value="Zivug Hagun">Zivug Hagun</option>
                              <option value="Zera Shel Kayama">Zera Shel Kayama</option>
                              <option value="Leiluy Nishmat">Leiluy Nishmat</option>
                            </CFormSelect>
                          </div>

                          <CFormSwitch size="xl" label="Anonimo" defaultChecked={anonimo2} onChange={(e) => { setAnonimo2(e.target.checked) }} />

                        </div>

                        <br /><br />

                        <div style={{ 'margin': 'auto', 'width': '80' }}>
                          <CFormInput type="text" id="floatingInput" floatingLabel={`Nombre 3`} placeholder='' value={nombre3}
                            style={nombre3Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                            onChange={(e) => { setNombre3(e.target.value); e.target.value != '' ? setNombre3Vacio() : setNombre3Vacio('vacio') }} />
                        </div>

                        <br />

                        <div className='categoria_anonimo'>

                          <div style={{ 'width': '70%' }}>
                            <CFormSelect id="floatingSelect" aria-label="Floating label select example" onChange={(e) => { setCategoria3(e.target.value) }}
                              value={categoria3 == 'Beraja y Hatzlaja' ? 'Beraja y Hatzlaja' : categoria3 == 'Refua Shelema' ? 'Refua Shelema' : categoria3 == 'Parnasa Toba' ? 'Parnasa Toba' : categoria3 == 'Zivug Hagun' ? 'Zivug Hagun' : categoria3 == 'Zera Shel Kayama' ? 'Zera Shel Kayama' : categoria3 == 'Leiluy Nishmat' ? 'Leiluy Nishmat' : 'Beraja y Hatzlaja'} >
                              <option value="Beraja y Hatzlaja">Beraja y Hatzlaja</option>
                              <option value="Refua Shelema">Refua Shelema</option>
                              <option value="Parnasa Toba">Parnasa Toba</option>
                              <option value="Zivug Hagun">Zivug Hagun</option>
                              <option value="Zera Shel Kayama">Zera Shel Kayama</option>
                              <option value="Leiluy Nishmat">Leiluy Nishmat</option>
                            </CFormSelect>
                          </div>

                          <CFormSwitch size="xl" label="Anonimo" defaultChecked={anonimo3} onChange={(e) => { setAnonimo3(e.target.checked) }} />

                        </div>

                        <br />
                      </>
                      : null
                  }

                  {
                    cuantos > 4 ?
                      <>
                        <br />

                        <div style={{ 'margin': 'auto', 'width': '80' }}>
                          <CFormInput type="text" id="floatingInput" floatingLabel={`Nombre 4`} placeholder='' value={nombre4}
                            style={nombre4Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                            onChange={(e) => { setNombre4(e.target.value); e.target.value != '' ? setNombre4Vacio() : setNombre4Vacio('vacio') }} />
                        </div>

                        <br />

                        <div className='categoria_anonimo'>

                          <div style={{ 'width': '70%' }}>
                            <CFormSelect id="floatingSelect" aria-label="Floating label select example" onChange={(e) => { setCategoria4(e.target.value) }}
                              value={categoria4 == 'Beraja y Hatzlaja' ? 'Beraja y Hatzlaja' : categoria4 == 'Refua Shelema' ? 'Refua Shelema' : categoria4 == 'Parnasa Toba' ? 'Parnasa Toba' : categoria4 == 'Zivug Hagun' ? 'Zivug Hagun' : categoria4 == 'Zera Shel Kayama' ? 'Zera Shel Kayama' : categoria4 == 'Leiluy Nishmat' ? 'Leiluy Nishmat' : 'Beraja y Hatzlaja'} >
                              <option value="Beraja y Hatzlaja">Beraja y Hatzlaja</option>
                              <option value="Refua Shelema">Refua Shelema</option>
                              <option value="Parnasa Toba">Parnasa Toba</option>
                              <option value="Zivug Hagun">Zivug Hagun</option>
                              <option value="Zera Shel Kayama">Zera Shel Kayama</option>
                              <option value="Leiluy Nishmat">Leiluy Nishmat</option>
                            </CFormSelect>
                          </div>

                          <CFormSwitch size="xl" label="Anonimo" defaultChecked={anonimo4} onChange={(e) => { setAnonimo4(e.target.checked) }} />

                        </div>

                        <br /><br />

                        <div style={{ 'margin': 'auto', 'width': '80' }}>
                          <CFormInput type="text" id="floatingInput" floatingLabel={`Nombre 5`} placeholder='' value={nombre5}
                            style={nombre5Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                            onChange={(e) => { setNombre5(e.target.value); e.target.value != '' ? setNombre5Vacio() : setNombre5Vacio('vacio') }} />
                        </div>

                        <br />

                        <div className='categoria_anonimo'>

                          <div style={{ 'width': '70%' }}>
                            <CFormSelect id="floatingSelect" aria-label="Floating label select example" onChange={(e) => { setCategoria5(e.target.value) }}
                              value={categoria5 == 'Beraja y Hatzlaja' ? 'Beraja y Hatzlaja' : categoria5 == 'Refua Shelema' ? 'Refua Shelema' : categoria5 == 'Parnasa Toba' ? 'Parnasa Toba' : categoria5 == 'Zivug Hagun' ? 'Zivug Hagun' : categoria5 == 'Zera Shel Kayama' ? 'Zera Shel Kayama' : categoria5 == 'Leiluy Nishmat' ? 'Leiluy Nishmat' : 'Beraja y Hatzlaja'} >
                              <option value="Beraja y Hatzlaja">Beraja y Hatzlaja</option>
                              <option value="Refua Shelema">Refua Shelema</option>
                              <option value="Parnasa Toba">Parnasa Toba</option>
                              <option value="Zivug Hagun">Zivug Hagun</option>
                              <option value="Zera Shel Kayama">Zera Shel Kayama</option>
                              <option value="Leiluy Nishmat">Leiluy Nishmat</option>
                            </CFormSelect>
                          </div>

                          <CFormSwitch size="xl" label="Anonimo" defaultChecked={anonimo5} onChange={(e) => { setAnonimo5(e.target.checked) }} />

                        </div>

                        <br />
                      </>
                      : null
                  }
                </div>
                <br />
              </>
              : null
          }
        </CModalBody>

        <CModalFooter>
          <CButton color="info" onClick={() => { actualizaNombres() }}>Guardar</CButton>
          <CButton color="danger" onClick={() => { setVisible2(false); limpia() }}>Cancelar</CButton>
        </CModalFooter>
      </CModal>

      {
        listo ?
          <>
            <div className='contenidosAdminDonador'>
              {
                !datos ?
                  <>
                    <img className="img-fluid" src={Logo} alt="" />

                    {timeOut ? <><br /><p style={{ "fontWeight": "bold", "color": "red" }}>El tiempo de vida de esta URL ha vencido, porfavor solicitalo nuevamente.</p></> : null}

                    {error ? <><br /><p style={{ "fontWeight": "bold", "color": "red" }}>Ocurrio un error, por favor intetenta nuevamente.</p></> : null}

                    <br />

                    <h1>לעילוי נשמת זכייה בת סליא</h1>


                    <h3>Editar mis suscripciones.</h3>

                    <br />

                    <p>Por favor ingresa tu correo electronico para continuar.</p>

                    <CForm>
                      <center>
                        <div style={{ "maxWidth": "35vw" }}>
                          <CFormInput type="email" id="exampleFormControlInput1" placeholder="" value={email} aria-describedby="exampleFormControlInputHelpInline"
                            style={emailVacio2 == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                            onChange={(e) => {
                              setEmail(e.target.value)

                              e.target.value == '' ?
                                setEmailVacio2('vacio')
                                :
                                !/\S+@\S+\.\S+/.test(e.target.value) ?
                                  setEmailVacio2('vacio')
                                  :
                                  setEmailVacio2()
                            }}
                          />

                          <br />

                        </div>
                      </center>

                      {
                        loading ?
                          <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
                          :
                          <>
                            <div className="d-flex justify-content-evenly">

                              {/* <CButton style={{ "float": "left" }} color='dark' variant="outline" onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left"></i>Atras</CButton> */}

                              <CButton color="warning" onClick={() => { handleSubmit() }}>Continuar</CButton>
                            </div>
                          </>
                      }
                    </CForm>

                    <br /><br />

                    <div>
                      <p>Si tienes algun problema puedes contactarnos aqui.</p>

                      <CButton color="info" onClick={() => { window.open('https://supportac.netlify.app/') }}>Contacto</CButton>
                    </div>
                  </>
                  :
                  <>
                    <img className="img-fluid" src={Logo} alt="" />

                    <br /><br /><br />

                    <p>Bienvenido {nombreDonador}</p>

                    <br />

                    {/* <p>Modifica tu{0 == 0 ? 's' : null} suscripcion{0 == 0 ? 'es' : null}</p>

                    <br /> */}

                    <p>Estas son tus suscripciones activas, por favor selecciona la que desees modificar.</p>

                    <br />

                    <div style={{ marginLeft: '5%', marginRight: '5%' }}>
                      <CTable responsive style={{ color: " rgb(226, 153, 45)" }}>
                        <CTableHead>
                          <CTableRow className='enmedio'>
                            {/* <th scope="col"></th> */}
                            <CTableHeaderCell scope="col">Cantidad</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Nombres</CTableHeaderCell>
                            <CTableHeaderCell scope="col"></CTableHeaderCell>
                            <CTableHeaderCell scope="col">Tipo de Suscripción</CTableHeaderCell>
                            <CTableHeaderCell scope="col"></CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>

                        <CTableBody>
                          {
                            datos.map((item, index) => {
                              return (
                                <>
                                  <CTableRow className='enmedio'>
                                    {/* <th scope="row"></th> */}
                                    <CTableDataCell className='cantidad'>${item.cantidad} / Mensual</CTableDataCell>

                                    <CTableDataCell>
                                      <ul className="no-bullets">
                                        {
                                          item.nombres.map((item2) => {
                                            return (
                                              <>
                                                <li>
                                                  <div className='subs'>
                                                    <p className='min'>{item2.nombre}</p>
                                                    <p className='min'>{item2.categoria}</p>
                                                    <p className='min'>{item2.anonimo ? 'Anónimo' : <>&nbsp;</>}</p>
                                                  </div>
                                                </li>
                                              </>
                                            )
                                          })
                                        }
                                      </ul>
                                    </CTableDataCell>

                                    <CTableDataCell>
                                      <CButton color='info' shape="rounded-pill" onClick={() => { setVisible2(true); setCual(index); setSubscriptionID(datos[index]._id) }}>Editar nombres<CIcon icon={cilAddressBook} size="xl" /></CButton>
                                    </CTableDataCell>

                                    {
                                      item.stripeOpaypal == 'Stripe' ?
                                        <>
                                          <CTableDataCell><i className="icono fa-brands fa-cc-stripe"></i></CTableDataCell>

                                          <CTableDataCell>
                                            <section>
                                              <form action={`${process.env.REACT_APP_BackURL}/create-portal-session`} method="POST">
                                                <input type="hidden" id="session-id" name="session_id" value={item.sesionStripeID} />
                                                <CButton id="checkout-and-portal-button" type="submit">Editar / Cancelar Suscripción</CButton>
                                              </form>
                                            </section>
                                          </CTableDataCell>
                                        </>
                                        :
                                        <>
                                          <CTableDataCell><i className="icono fa-brands fa-cc-paypal"></i></CTableDataCell>
                                          {/* <CTableDataCell>{item.suscripcionPaypalID}</CTableDataCell> */}

                                          <CTableDataCell>
                                            <CButton id="checkout-and-portal-button" onClick={() => { cancelarPaypal(item.suscripcionPaypalID, datos.length) }}>Cancelar Suscripción</CButton>
                                          </CTableDataCell>
                                        </>
                                    }
                                  </CTableRow>

                                  {/* <div className='subs'>
                                  <p>Donativo por: ${item.cantidad}</p>
                                  <p>Nombres</p>
                                  <div></div>
                                  <section>
                                    <form action={`${process.env.REACT_APP_BackURL}/create-portal-session`} method="POST">
                                      <input type="hidden" id="session-id" name="session_id" value={item.sesionStripeID} />
                                      <CButton id="checkout-and-portal-button" type="submit">Editar</CButton>
                                    </form>
                                  </section>
                                </div>
                                <br /> */}
                                </>
                              )
                            })
                          }
                        </CTableBody>
                      </CTable>
                    </div>

                    <br /><br />

                    <CButton color='info' shape="rounded-pill" onClick={() => { setVisible1(true); setCual(0) }}>Editar mis datos</CButton>

                    <br /><br />

                    <p>¿Quieres contratar otro paquete? Puedes hacerlo dando <a href="https://tehilatenu.ae.edu.mx">click aquí</a></p>
                    <br /><br />

                  </>
              }

              <br />

              <center>
                <img src={LogoYeshiba} alt={"Active Code"} style={{ 'width': widthLogos, "height": "auto" }} />

                <br /> <br /> <br />

                <div className="container_row">
                  <div className="layer1">
                    <img id='mario' src={mario} alt={"Active Code"} style={{ 'width': "3%", "height": "auto" }} />
                  </div>
                  <div className="layer2">
                    <img src={logoActive} alt={"Active Code"} onClick={logoHandler} style={{ 'width': widthLogos, "height": "auto" }} />
                  </div>
                </div>
              </center>

              <br /><br />
            </div>
          </>

          : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
      }
    </>
  );
}

export default AdminDonador