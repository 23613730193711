import React, { useState, useEffect, useLayoutEffect } from 'react';
import { CButton, CDropdownToggle, CFormLabel, CForm, CDropdown, CDropdownItem, CDropdownMenu, CFormInput } from '@coreui/react'
import { isMobile, BrowserView, MobileView, } from 'react-device-detect';
import './RegistroVendedor.css'
import Swal from "sweetalert2"
import axios from 'axios'
import ReactLoading from 'react-loading';
import Logo from '../assets/logo.png'
import LogoYeshiba from '../assets/logoYeshiba.png'
import logoActive from "../assets/LogoActiveAnimated.gif";
import mario from '../assets/mario.png'
import useSound from "use-sound";
import marioJump from "../sounds/marioJump.mp3";

function RegistroVendedor() {
  const [play] = useSound(marioJump)
  let [count, setCount] = useState(1)
  let [loading, setLoading] = useState(false)
  let [title, setTitle] = useState('Sr')
  let [name, setName] = useState(undefined)
  let [email, setEmail] = useState(undefined)
  let [cel, setCel] = useState(undefined)
  let [apellidoFamilia, setApellidoFamilia] = useState(undefined)
  let [codigoFamilia, setCodigoFamilia] = useState(undefined)
  let [widthLogos, setWidthLogos] = useState('8%')
  let [nameVacio, setNameVacio] = useState()
  let [emailVacio, setEmailVacio] = useState()
  let [celVacio, setCelVacio] = useState()
  let [apellidoFamiliaVacio, setApellidoFamiliaVacio] = useState()
  let [codigoFamiliaVacio, setCodigoFamiliaVacio] = useState()

  function handleSubmit() {
    if (!name || !email || !/\S+@\S+\.\S+/.test(email) || !cel || cel.length < 10 || !apellidoFamilia || !codigoFamilia) {
      if (name == undefined) { setNameVacio("vacio") }
      if (email == undefined) { setEmailVacio("vacio") }
      if (cel == undefined) { setCelVacio("vacio") }
      if (apellidoFamilia == undefined) { setApellidoFamiliaVacio("vacio") }
      if (codigoFamilia == undefined) { setCodigoFamiliaVacio("vacio") }

      Swal.fire('Informacion incompleta, por favor llena todos los campos.')
    }

    else {
      setLoading(true)

      axios.get(`${process.env.REACT_APP_BackURL}/getAllAbrejim`)
        .then((response) => {
          let exist = false

          let data = {
            "titulo": title,
            "nombre": name,
            "apellidoFamilia": apellidoFamilia,
            "cel": cel,
            "mail": email,
            "codigoFamilia": codigoFamilia
          }

          for (let i in response.data.data) { if (response.data.data[i].codigoFamilia == codigoFamilia) { exist = true } }

          if (exist) { setLoading(false); Swal.fire('Ya hay un registro con ese codigo de familia, por favor revisalo e intenta de nuevo.') }

          else {
            axios.post(`${process.env.REACT_APP_BackURL}/insertAbrej`, data)
              .then(() => {
                Swal.fire('Registro enviado con exito! En cuanto seas aprovado recibiras un correo con tu informacion y link de vendedor.')
                  .then(() => { window.location.reload() })
                setLoading(false)
              })

              .catch((err) => { setLoading(false); Swal.fire('Ocurrio un error, intetenta de nuevo por favor.') })
          }
        })
        .catch((err) => { setLoading(false); Swal.fire('Ocurrio un error, intetenta de nuevo por favor.') })
    }
  }

  function logoHandler() {
    let temp = count

    if (count == 14) {
      play()
      document.getElementById('mario').classList.add("mario")
      setTimeout(() => { document.getElementById('mario').classList.remove("mario") }, 600);
      setCount(1)
    }
    else { temp++; setCount(temp) }
  }

  useLayoutEffect(() => {
    if (isMobile) { setWidthLogos('30vw') }
  }, [])

  return (
    <>
      <div className='contenidosRegistroVendedor'>
        <div className='container'>

          <img className="img-fluid" src={Logo} alt="" />

          <br />

          <h1>לעילוי נשמת זכייה בת סליא</h1>

          <br />

          <h3 style={{ 'textAlign': 'center' }}>Registro para referidos</h3>

          <br />

          <CForm>
            <div className='row'>
              <div className='col-sm-2 offset-md-2'>

                <br />

                <CFormLabel htmlFor="exampleDropdownFormEmail1">Título</CFormLabel>

                <br />

                <CDropdown>
                  <CDropdownToggle color="light">{title}</CDropdownToggle>

                  <CDropdownMenu onClick={(e) => { setTitle(e.target.text) }}>
                    <CDropdownItem value='Jajam'>Rab</CDropdownItem>
                    <CDropdownItem value='Sr'>Sr</CDropdownItem>
                    <CDropdownItem value='Sra'>Sra</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </div>

              <div style={{ "minWidth": "400px" }} className='col-sm-6'>
                <br />

                <CFormInput type="text" id="exampleFormControlInput1" label="Nombre Completo" placeholder="" aria-describedby="exampleFormControlInputHelpInline"
                  style={nameVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => { setName(e.target.value); e.target.value != '' ? setNameVacio() : setNameVacio('vacio') }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-2 offset-md-2'>
                <br />
                <CFormInput type="number" id="exampleFormControlInput1" placeholder="" aria-describedby="exampleFormControlInputHelpInline"
                  label={celVacio === "vacio" ? "Celular (10 digitos)" : "Celular"}
                  style={celVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => {
                    setCel(e.target.value);

                    e.target.value == "" ?
                      setCelVacio("vacio")
                      :
                      e.target.value.length < 10 ? setCelVacio("vacio")
                        :
                        setCelVacio()
                  }}
                />
              </div>

              <div className='col-sm-6'>
                <br />

                <CFormInput type="email" id="exampleFormControlInput1" label="Correo electrónico" placeholder="" aria-describedby="exampleFormControlInputHelpInline"
                  style={emailVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => {
                    setEmail(e.target.value)

                    e.target.value == '' ?
                      setEmailVacio('vacio')
                      :
                      !/\S+@\S+\.\S+/.test(e.target.value) ?
                        setEmailVacio('vacio')
                        :
                        setEmailVacio()
                  }}
                />
              </div>
            </div>

            <div className='row'>

              <div className='col-sm-2 offset-md-2'>
                <br />

                <CFormInput type="text" id="exampleFormControlInput1" label="Código de Familia" placeholder="Ejemplo AA11 " aria-describedby="exampleFormControlInputHelpInline"
                  style={codigoFamiliaVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => { setCodigoFamilia(e.target.value); e.target.value != '' ? setCodigoFamiliaVacio() : setCodigoFamiliaVacio('vacio') }}
                />
              </div>

              <div className='col-sm-6'>
                <br />

                <CFormInput type="text" id="exampleFormControlInput1" label="Apellido de Familia" placeholder="" aria-describedby="exampleFormControlInputHelpInline"
                  style={apellidoFamiliaVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => { setApellidoFamilia(e.target.value); e.target.value != '' ? setApellidoFamiliaVacio() : setApellidoFamiliaVacio('vacio') }}
                />
              </div>

            </div>
          </CForm>

          <br /><br />

          <div className='row'>
            <div className='col-sm-4 offset-md-4'>
              {
                loading ?
                  <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
                  :
                  <>
                    <CButton style={{ 'width': '100%' }} color="info" onClick={() => { handleSubmit() }}>Registrarme</CButton>
                  </>
              }
            </div>
          </div>

          <br /> <br />

        </div>

        <center>
          <img src={LogoYeshiba} alt={"Active Code"} style={{ 'width': widthLogos, "height": "auto" }} />

          <br /> <br /> <br />

          <div className="container_row">
            <div className="layer1">
              <img id='mario' src={mario} alt={"Active Code"} style={{ 'width': "3%", "height": "auto" }} />
            </div>
            <div className="layer2">
              <img src={logoActive} alt={"Active Code"} onClick={logoHandler} style={{ 'width': widthLogos, "height": "auto" }} />
            </div>
          </div>
        </center>

        <br /><br />

      </div>
    </>
  );
}

export default RegistroVendedor
