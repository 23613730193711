import React, { createContext, useState, useEffect } from "react";
import decode from "jwt-decode";
import Swal from "sweetalert2";

export const AuthContext = createContext();

const AuthProvider = (props) => {
  const [token, setToken] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [user1, setUser1] = useState({});

  const loginUser = (token) => {
    localStorage.setItem("app_token", token);
    const decoded = decode(token);
    setUser1(decoded);
    setToken(token);
    setIsAuth(true);
  };

  const logOut = (token) => {
    localStorage.removeItem("app_token"); setToken({}); setIsAuth(false); setUser1({})

    Swal.fire({ icon: "warning", title: "Hasta luego", allowEscapeKey: true, timer: 2000 })
      .then(() => { window.location.href = "/login" })
  };

  // const verificaSesion = () => {
  //   let token = localStorage.getItem("app_token");
  //   let dateNow = Math.floor(Date.now() / 1000);
  //   let decoded = decode(token);
  //   let timexp = decoded.exp;

  //   if (dateNow >= timexp) {
  //     localStorage.removeItem("app_token"); setToken({}); setIsAuth(false); setUser1({})

  //     Swal.fire({ icon: "warning", title: "Tu sesión caducó", allowEscapeKey: true })
  //       .then(() => { window.location.href = "/" })
  //   }
  // };

  return (
    <AuthContext.Provider
      value={{ token, isAuth, user1, loginUser, logOut }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
