import React, { useState, useLayoutEffect } from 'react'
import { CButton, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'

import axios from 'axios'
import ReactLoading from 'react-loading';
import './Gifts.css'

function Gifts() {
    let [loading, setLoading] = useState(true)
    let [gifts, setGifts] = useState([])

    function getGifts() {
        axios.get(`${process.env.REACT_APP_BackURL}/getAllGifts`)
            .then((response) => { setGifts(response.data.data); setLoading(false) })
            .catch((err) => { console.log(err) })
    }

    useLayoutEffect(() => {
        getGifts()
    }, [])

    return (
        <>
            <div className='centro'>

                {
                    !loading ?
                        <>

                            <CButton onClick={() => {
                                axios.post(`${process.env.REACT_APP_BackURL}/insertgift`)
                                    .then(() => { getGifts() })
                                    .catch((err) => { console.log(err) })

                            }}>Crear gift</CButton>

                            <br />                        <br />
                            <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
                                <CTableHead>
                                    <CTableRow color='info'>
                                        <CTableHeaderCell>Fecha</CTableHeaderCell>
                                        <CTableHeaderCell>Status</CTableHeaderCell>
                                        <CTableHeaderCell>Codigo</CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>

                                <CTableBody>
                                    {
                                        gifts.length > 0 ?
                                            <>
                                                {
                                                    gifts.map((item) => {
                                                        return (
                                                            <>
                                                                <CTableRow>
                                                                    <CTableDataCell>{new Date(item.createdAt).toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' })}</CTableDataCell>
                                                                    <CTableDataCell>{item.is_active ? "Disponible" : "Usado"}</CTableDataCell>
                                                                    <CTableDataCell>{item.ticket}</CTableDataCell>
                                                                </CTableRow>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                <CTableRow>
                                                    <CTableDataCell colSpan="7">Sin Resultados</CTableDataCell>
                                                </CTableRow>
                                            </>
                                    }

                                </CTableBody>
                            </CTable>
                        </>
                        : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
                }
            </div>
        </>
    )
}

export default Gifts
