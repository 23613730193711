import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilList, cilBriefcase, cilGroup, cilHistory, cilAddressBook, cilDrop, cilNotes, cilPencil, cilPuzzle, cilSpeedometer, cilStar, cil3d, } from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Vendedores',
    to: '/vendedores',
    icon: <CIcon icon={cilBriefcase} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Donadores',
    to: '/donadores',
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Suscripciones',
    to: '/suscripciones',
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Suscripciones Gratis',
    to: '/suscripcionesgratis',
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Gifts',
    to: '/giftslista',
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Unico x día',
    to: '/unicos',
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Usuarios',
    to: '/usuarios',
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Historial Descuentos',
    to: '/historial',
    icon: <CIcon icon={cilHistory} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },

  {
    component: CNavTitle,
    name: 'Landings',
  },
  {
    component: CNavItem,
    name: 'Yeshuot',
    to: '/',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Gifts',
    to: '/gift',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Registro Vendedor',
    to: '/registro',
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Admin Donador',
    to: '/cuenta',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Gracias',
    to: '/gracias',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Pantalla Nombres',
    to: '/pantallanombres',
    icon: <CIcon icon={cil3d} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavItem,
    name: 'Pantalla Nombres sin Anonimos',
    to: '/pantallanombresanonimo',
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  }

]

export default _nav
