import React, { useState, useLayoutEffect, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
// import { AuthContext } from "../contexts/AuthContext";
import { AppSidebar, AppFooter, AppHeader } from '../layout/index'
import decode from "jwt-decode";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const [token, setToken] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [user1, setUser1] = useState({});
  const [acabo, setAcabo] = useState(false);

  useLayoutEffect(() => {
    let item = localStorage.getItem("app_token")

    if (item) {
      let decoded = decode(item)
      let dateNow = Math.floor(Date.now() / 1000);
      let timexp = decoded.exp;

      if (dateNow >= timexp) { localStorage.removeItem("app_token"); setToken({}); setIsAuth(false); setUser1({}); setAcabo(true) }
      else { setUser1(decoded); setToken(item); setIsAuth(true); setAcabo(true) }
    }
    else { setAcabo(true) }
  }, [])

  return (
    <>
      {
        acabo ?
          <>
            <Route
              {...rest}
              render={(routeProps) =>
                !!user1 ?
                  isAuth ?
                    <>
                      <div>
                        <AppSidebar />
                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                          <AppHeader />
                          <div className="body flex-grow-1 px-3">
                            <RouteComponent {...routeProps} />
                          </div>
                          <AppFooter />
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <Redirect to={"/login"} />
                    </>
                  :
                  <>
                    <Redirect to={"/login"} />
                  </>
              }
            />
          </>
          : null
      }
    </>
  )
};

export default PrivateRoute;
