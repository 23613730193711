import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import axios from 'axios'

function CheckoutPaypalUnico(props) {
    const createOrder = (data, actions) => {
        return actions.order.create({ purchase_units: [{ amount: { value: "1800" } }] })
    }

    const onApprove = (data, actions) => {
        // Entra aca cuando pasa el pago

        let body = {
            event_type: 'CHECKOUT.ORDER.APPROVEDmio',
            mario: process.env.REACT_APP_MARIO,
            metadata: {
                abrejID: props.abrejID,
                donadorID: '',
                nombreCompleto: props.nombreCompletoUnico,
                cantidad: 1800,
                mail: props.emailUnico,
                cel: props.celUnico,
                nombres: props.nombresUnico,
                codigoFamilia: props.codigoFamilia,
                fecha: props.fechaUnico
            }
        }

        axios.post(`${process.env.REACT_APP_BackURL}/webhookPaypal`, body)
            .then(() => { window.location.replace('https://tehilatenu.ae.edu.mx/gracias') })
            .catch((err) => { Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })

        return actions.order.capture()
    }

    return (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, currency: "MXN" }}>

            <PayPalButtons
                style={{ shape: 'pill', color: 'blue', layout: 'horizontal', label: 'paypal' }}
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
            />

        </PayPalScriptProvider>)
}

export default CheckoutPaypalUnico