import React, { useState, useEffect, useLayoutEffect } from 'react'
// import { IonContent, IonPage, IonGrid, IonRow, useIonAlert, IonCol, IonButton, IonSegmentButton, IonCardTitle, IonCardContent, IonCardSubtitle, IonCard, IonSegment } from "@ionic/react";
import { CButton, CAlert, CButtonGroup, CFormInput, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
// import Table from 'react-bootstrap/Table';
import axios from 'axios'
import ReactLoading from 'react-loading';
import exportFromJSON from "export-from-json";
import './Vendedores.css'

function Vendedores() {
  let [loading, setLoading] = useState(true)
  let excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara
  let [visible1, setVisible1] = useState(false)
  let [visible2, setVisible2] = useState(false)
  let [visible3, setVisible3] = useState(false)

  let [acabo1, setAcabo1] = useState(false)
  let [a, setA] = useState()

  let [abrejimOG, setAbrejimOG] = useState([])
  let [abrejim, setAbrejim] = useState([])
  let [section, setSection] = useState("aceptados")

  let [result1, setResult1] = useState(false)
  let [result2, setResult2] = useState(false)
  let [result3, setResult3] = useState(false)

  function getAbrejim() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllAbrejim`)
      .then((response) => { setAbrejim(response.data.data); setAbrejimOG(response.data.data); setAcabo1(true) })
      .catch((err) => { console.log(err) })
  }

  function getSuscripcionesActivas() {
    for (let i in abrejim) {
      axios.get(`${process.env.REACT_APP_BackURL}/getAllSuscripciones`)
        .then((response) => {
          let cuantas = 0

          for (let i2 in response.data.data) {
            if (response.data.data[i2].abrejID._id == abrejim[i]._id) {
              cuantas++
            }

            if (response.data.data.length == Number(i2) + 1) {
              abrejim[i].suscripcionesActivas = cuantas
              setA(Math.random())
            }
          }
        })
        .catch((err) => { console.log(err) })
    }
  }

  function aceptar(id) {
    axios.patch(`${process.env.REACT_APP_BackURL}/acceptAbrej?id=${id}`)
      .then(() => { setVisible1(true); getAbrejim() })
      .catch((err) => { setVisible3(true) })
  }

  function rechazar(id) {
    axios.patch(`${process.env.REACT_APP_BackURL}/rejectAbrej?id=${id}`)
      .then(() => { setVisible2(true); getAbrejim() })
      .catch((err) => { setVisible3(true) })
  }

  function exportarExcel() {
    let info = []
    if (section == "aceptados") { info = abrejim.filter((item) => item.activo && !item.rechazado) }
    if (section == "porAceptar") { info = abrejim.filter((item) => !item.activo && !item.rechazado) }
    if (section == "rechazados") { info = abrejim.filter((item) => item.rechazado) }

    let datos = []
    let nombre = info.map((v) => v.nombre)
    let apellidoFamilia = info.map((v) => v.apellidoFamilia)
    let cel = info.map((v) => v.cel)
    let mail = info.map((v) => v.mail)
    let codigoFamilia = info.map((v) => v.codigoFamilia)
    let descuentoAcumulado = info.map((v) => v.descuento)
    let suscripcionesActivas = info.map((v) => v.suscripcionesActivas)

    for (var i = 0; i < nombre.length; i++) {
      if (nombre[i] != "N/A") {
        datos.push({
          'Nombre': nombre[i],
          'Apellido de Familia': apellidoFamilia[i],
          'Celular': cel[i],
          'Mail': mail[i],
          'Codigo de Familia': codigoFamilia[i],
          'Descuento Acumulado': `$${descuentoAcumulado[i]}`,
          'No de Suscripciones Activas': suscripcionesActivas[i]
        })
      }
    }

    exportFromJSON({ data: datos, fileName: "Reporte_Vendedores", exportType: "csv" })
  }

  // handle change event of search input
  const handleChange = (value) => {
    filterData(value)
  };

  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setAbrejim(abrejimOG);
    else {
      const filteredData = abrejimOG.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setAbrejim(filteredData);
    }
  };

  useLayoutEffect(() => {
    getAbrejim()
  }, [])

  useEffect(() => {
    if (abrejim.length > 0) { getSuscripcionesActivas() }
  }, [abrejim])

  useEffect(() => {
    if (visible1) { setTimeout(() => { setVisible1(false) }, 5000); }
    if (visible2) { setTimeout(() => { setVisible2(false) }, 5000); }
    if (visible3) { setTimeout(() => { setVisible3(false) }, 5000); }
  }, [visible1, visible2, visible3])

  useEffect(() => {
    setResult1(false); setResult2(false); setResult3(false)

    if (section == 'aceptados') { for (let i in abrejim) { if (abrejim[i].activo && !abrejim[i].rechazado && abrejim[i].nombre != 'N/A') { setResult1(true) } } }
    if (section == 'porAceptar') { for (let i in abrejim) { if (!abrejim[i].activo && !abrejim[i].rechazado) { setResult2(true) } } }
    if (section == 'rechazados') { for (let i in abrejim) { if (abrejim[i].rechazado) { setResult3(true) } } }
  }, [section, abrejim])

  useEffect(() => {
    if (acabo1) { setLoading(false) }
  }, [acabo1])

  return (
    <>
      <div className='centro'>
        <p>Vendedores</p>

        <CAlert color="primary" dismissible visible={visible1} onClose={() => { getAbrejim(); setVisible1(false) }}>Abrej aceptado!</CAlert>
        <CAlert color="warning" dismissible visible={visible2} onClose={() => { getAbrejim(); setVisible2(false) }}>Abrej rechazado!</CAlert>
        <CAlert color="danger" dismissible visible={visible3} onClose={() => { setVisible3(false) }}>Ocurio un error, intenta de nuevo por favor.</CAlert>

        <center>
          <CButtonGroup value={section}>
            <CButton active={section == 'aceptados' ? true : false} onClick={(e) => { setSection(e.target.value) }} variant='outline' value="aceptados">Aceptados</CButton>
            <CButton active={section == 'porAceptar' ? true : false} onClick={(e) => { setSection(e.target.value) }} variant='outline' value="porAceptar">Por Aceptar</CButton>
            <CButton active={section == 'rechazados' ? true : false} onClick={(e) => { setSection(e.target.value) }} variant='outline' value="rechazados">Rechazados</CButton>
          </CButtonGroup>
        </center>

        <br /><br />

        <center>
          <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { handleChange(e.target.value) })} />
        </center>

        <br />

        {
          acabo1 ?
            <>
              {
                section == 'aceptados' ?
                  <>
                    <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
                      <CTableHead>
                        <CTableRow color='info'>
                          <CTableHeaderCell>Nombre</CTableHeaderCell>
                          <CTableHeaderCell>Apellido de Familia</CTableHeaderCell>
                          <CTableHeaderCell>Celular</CTableHeaderCell>
                          <CTableHeaderCell>Mail</CTableHeaderCell>
                          <CTableHeaderCell>Codigo de Familia</CTableHeaderCell>
                          <CTableHeaderCell>Descuento Acumulado</CTableHeaderCell>
                          <CTableHeaderCell>No de Suscripciones Activas</CTableHeaderCell>
                          <CTableHeaderCell>Total Ventas Mensuales</CTableHeaderCell>
                          <CTableHeaderCell><CTableHeaderCell><CButton color="success" shape="rounded-pill" onClick={exportarExcel}><i className="far fa-file-excel"></i> XLS</CButton></CTableHeaderCell></CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>

                      <CTableBody>
                        {
                          result1 ?
                            <>
                              {
                                abrejim.map((item) => {
                                  return (
                                    item.activo && !item.rechazado ?
                                      item.nombre != 'N/A' ?
                                        <>
                                          <CTableRow>
                                            <CTableDataCell>{item.nombre}</CTableDataCell>
                                            <CTableDataCell>{item.apellidoFamilia}</CTableDataCell>
                                            <CTableDataCell>{item.cel}</CTableDataCell>
                                            <CTableDataCell>{item.mail}</CTableDataCell>
                                            <CTableDataCell>{item.codigoFamilia}</CTableDataCell>
                                            <CTableDataCell>{item.descuento}</CTableDataCell>
                                            <CTableDataCell>{item.suscripcionesActivas}</CTableDataCell>
                                            <CTableDataCell>{item.descuento * 10}</CTableDataCell>
                                            <CTableDataCell><CButton shape="rounded-pill" color="danger" onClick={() => { rechazar(item._id) }}>Rechazar</CButton></CTableDataCell>
                                          </CTableRow>
                                        </>
                                        : null
                                      : null
                                  )
                                })
                              }
                            </>
                            :
                            <>
                              <CTableRow>
                                <CTableDataCell colSpan="9">Sin Resultados</CTableDataCell>
                              </CTableRow>
                            </>
                        }
                      </CTableBody>
                    </CTable>
                  </>

                  : section == 'porAceptar' ?
                    <>
                      <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
                        <CTableHead>
                          <CTableRow color='info'>
                            <CTableHeaderCell>Nombre</CTableHeaderCell>
                            <CTableHeaderCell>Apellido de Familia</CTableHeaderCell>
                            <CTableHeaderCell>Celular</CTableHeaderCell>
                            <CTableHeaderCell>Mail</CTableHeaderCell>
                            <CTableHeaderCell>Codigo de Familia</CTableHeaderCell>
                            <CTableHeaderCell><CTableHeaderCell><CButton color="success" shape="rounded-pill" onClick={exportarExcel}><i className="far fa-file-excel"></i> XLS</CButton></CTableHeaderCell></CTableHeaderCell>
                            <CTableHeaderCell></CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>

                        <CTableBody>
                          {
                            result2 ?
                              <>
                                {
                                  abrejim.map((item) => {
                                    return (
                                      !item.activo && !item.rechazado ?
                                        <>
                                          <CTableRow>
                                            <CTableDataCell>{item.nombre}</CTableDataCell>
                                            <CTableDataCell>{item.apellidoFamilia}</CTableDataCell>
                                            <CTableDataCell>{item.cel}</CTableDataCell>
                                            <CTableDataCell>{item.mail}</CTableDataCell>
                                            <CTableDataCell>{item.codigoFamilia}</CTableDataCell>
                                            <CTableDataCell><CButton shape="rounded-pill" onClick={() => { aceptar(item._id) }}>Aceptar</CButton></CTableDataCell>
                                            <CTableDataCell><CButton shape="rounded-pill" color="danger" onClick={() => { rechazar(item._id) }}>Rechazar</CButton></CTableDataCell>
                                          </CTableRow>
                                        </>
                                        : null
                                    )
                                  })
                                }
                              </>
                              :
                              <>
                                <CTableRow>
                                  <CTableDataCell colSpan="9">Sin Resultados</CTableDataCell>
                                </CTableRow>
                              </>
                          }
                        </CTableBody>
                      </CTable>
                    </>

                    :
                    <>
                      <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
                        <CTableHead>
                          <CTableRow color='info'>
                            <CTableHeaderCell>Nombre</CTableHeaderCell>
                            <CTableHeaderCell>Apellido de Familia</CTableHeaderCell>
                            <CTableHeaderCell>Celular</CTableHeaderCell>
                            <CTableHeaderCell>Mail</CTableHeaderCell>
                            <CTableHeaderCell>Codigo de Familia</CTableHeaderCell>
                            <CTableHeaderCell><CTableHeaderCell><CButton color="success" shape="rounded-pill" onClick={exportarExcel}><i className="far fa-file-excel"></i> XLS</CButton></CTableHeaderCell></CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>

                        <CTableBody>
                          {
                            result3 ?
                              <>
                                {
                                  abrejim.map((item) => {
                                    return (
                                      item.rechazado ?
                                        <>
                                          <CTableRow>
                                            <CTableDataCell>{item.nombre}</CTableDataCell>
                                            <CTableDataCell>{item.apellidoFamilia}</CTableDataCell>
                                            <CTableDataCell>{item.cel}</CTableDataCell>
                                            <CTableDataCell>{item.mail}</CTableDataCell>
                                            <CTableDataCell>{item.codigoFamilia}</CTableDataCell>
                                            <CTableDataCell><CButton shape="rounded-pill" onClick={() => { aceptar(item._id) }}>Aceptar</CButton></CTableDataCell>
                                          </CTableRow>
                                        </>
                                        : null
                                    )
                                  })
                                }
                              </>
                              :
                              <>
                                <CTableRow>
                                  <CTableDataCell colSpan="9">Sin Resultados</CTableDataCell>
                                </CTableRow>
                              </>
                          }
                        </CTableBody>
                      </CTable>
                    </>
              }
            </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

      </div>
    </>
  )
}

export default Vendedores
