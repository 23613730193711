import React, { useState, useEffect, useLayoutEffect } from 'react'
import { CButton, CModal, CFormInput, CModalHeader, CFormSwitch, CFormSelect, CModalTitle, CAlert, CModalBody, CModalFooter, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilAddressBook } from '@coreui/icons';
import axios from 'axios'
import ReactLoading from 'react-loading';
import exportFromJSON from "export-from-json";
import './Suscripciones.css'

function Suscripciones() {
  let [loading, setLoading] = useState(true)
  let [visible1, setVisible1] = useState(false)
  let [unicosOG, setUnicosOG] = useState([])
  let [unicos, setUnicos] = useState([])
  let [nombresDisplay, setNombresDisplay] = useState([])

  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara

  function getSuscripciones() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllPagosUnicos`)
      .then((response) => { setUnicos(response.data.data); setUnicosOG(response.data.data); setLoading(false) })
      .catch((err) => { console.log(err) })
  }

  function exportarExcel() {
    let datos = []
    let cantidad = unicos.map((v) => v.cantidad)
    let createdAt = unicos.map((v) => v.createdAt)
    let donador = unicos.map((v) => v.donadorID.nombreCompleto)
    let vendedor = unicos.map((v) => v.abrejID.nombre)
    let stripeOpaypal = unicos.map((v) => v.stripeOpaypal)

    for (var i = 0; i < cantidad.length; i++) {
      datos.push({
        'Cantidad': `$${cantidad[i]}`,
        'Desde': new Date(createdAt[i]).toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' }),
        'Donador': donador[i],
        'Vendedor': vendedor[i],
        'Stripe / PayPal': stripeOpaypal[i],
      });
    }

    exportFromJSON({ data: datos, fileName: "Reporte_Suscripciones", exportType: "csv" })
  }

  // handle change event of search input
  const handleChange = (value) => {
    filterData(value)
  };

  // // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setUnicos(unicosOG);
    else {
      const filteredData = unicosOG.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setUnicos(filteredData);
    }
  };

  useLayoutEffect(() => {
    getSuscripciones()
  }, [])

  return (
    <>
      <div className='centro'>
        <CModal visible={visible1} onClose={() => setVisible1(false)}>
          <CModalHeader onClose={() => setVisible1(false)}>
            <CModalTitle>Nombres</CModalTitle>
          </CModalHeader>

          <CModalBody>
            <CTable responsive color="dark" hover>
              <CTableHead>
                <CTableRow color='info'>
                  <CTableHeaderCell>Nombre</CTableHeaderCell>
                  <CTableHeaderCell>Categoria</CTableHeaderCell>
                  <CTableHeaderCell>Anonimo</CTableHeaderCell>
                </CTableRow>
              </CTableHead>

              <CTableBody>
                {
                  nombresDisplay.map((item) => {
                    return (
                      <>
                        <CTableRow>
                          <CTableDataCell>{item.nombre}</CTableDataCell>
                          <CTableDataCell>{item.categoria}</CTableDataCell>
                          <CTableDataCell>{!item.anonimo ? 'No' : 'Si'}</CTableDataCell>
                        </CTableRow>
                      </>
                    )
                  })
                }
              </CTableBody>
            </CTable>
          </CModalBody>

          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible1(false)}>Cerrar</CButton>
          </CModalFooter>
        </CModal>

        <center>
          <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { handleChange(e.target.value) })} />
        </center>

        <br />

        {
          !loading ?
            <>
              <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
                <CTableHead>
                  <CTableRow color='info'>
                    <CTableHeaderCell>Cantidad</CTableHeaderCell>
                    <CTableHeaderCell>Fecha</CTableHeaderCell>
                    <CTableHeaderCell>Donador</CTableHeaderCell>
                    <CTableHeaderCell>Nombres</CTableHeaderCell>
                    <CTableHeaderCell>Vendedor</CTableHeaderCell>
                    <CTableHeaderCell>Stripe / Paypal</CTableHeaderCell>
                    <CTableHeaderCell><CButton color="success" shape="rounded-pill" onClick={exportarExcel}><i className="far fa-file-excel"></i> XLS</CButton></CTableHeaderCell>
                  </CTableRow>
                </CTableHead>

                <CTableBody>
                  {
                    unicos.length > 0 ?
                      <>
                        {
                          unicos.map((item) => {
                            return (
                              <>
                                <CTableRow>
                                  <CTableDataCell>${item.cantidad}</CTableDataCell>
                                  <CTableDataCell>{new Date(item.fecha).toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' })}</CTableDataCell>
                                  <CTableDataCell>{item.donadorID.nombreCompleto}</CTableDataCell>
                                  <CTableDataCell><CButton color='info' shape="rounded-pill" onClick={() => { setVisible1(true); setNombresDisplay(item.nombres) }}><CIcon icon={cilAddressBook} size="xl" /></CButton></CTableDataCell>
                                  <CTableDataCell>{item.abrejID.nombre}</CTableDataCell>
                                  <CTableDataCell>{item.stripeOpaypal}</CTableDataCell>
                                  <CTableDataCell></CTableDataCell>
                                </CTableRow>
                              </>
                            )
                          })
                        }
                      </>
                      :
                      <>
                        <CTableRow>
                          <CTableDataCell colSpan="7">Sin Resultados</CTableDataCell>
                        </CTableRow>
                      </>
                  }

                </CTableBody>
              </CTable>
            </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }
      </div>
    </>
  )
}

export default Suscripciones
