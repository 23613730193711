import React, { useEffect, useState } from 'react'
import { isMobile, BrowserView, MobileView, } from 'react-device-detect';
import './Gracias.css'
import Logo from '../assets/logo.png'
import LogoYeshiba from '../assets/logoYeshiba.png'
import logoActive from "../assets/LogoActiveAnimated.gif";
import mario from '../assets/mario.png'
import useSound from "use-sound";
import marioJump from "../sounds/marioJump.mp3";
import { CButton } from '@coreui/react'
import lottie from "lottie-web";
import animation1 from "../assets/lottie/18127-book-animation.json";
import animation2 from "../assets/lottie/42814-magic-book.json";

function Gracias() {
    let [widthLogos, setWidthLogos] = useState('8%')
    let [widthTehilatenu, setWidthTehilatenu] = useState('40vw')
    let [count, setCount] = useState(1)
    const [play] = useSound(marioJump);

    function logoHandler() {
        let temp = count

        if (count == 14) {
            play()
            document.getElementById('mario').classList.add("mario")
            setTimeout(() => { document.getElementById('mario').classList.remove("mario") }, 600);
            setCount(1)
        }
        else { temp++; setCount(temp) }
    }

    useEffect(() => {
        if (isMobile) { setWidthLogos('50vw') }
        if (isMobile) { setWidthTehilatenu('70vw') }
        lottie.loadAnimation({
            container: document.querySelector("#complete"),
            // animationData: animation1,
            animationData: animation2,
            loop: true
        })
    }, [])

    return (
        <div className='contenidosGracias'>
            <img style={{ "width": widthTehilatenu, "height": "auto" }} src={Logo} alt="Tehilatenu" onClick={() => { window.location.replace('https://tehilatenu.ae.edu.mx') }} />

            <br />

            <h1>לעילוי נשמת זכייה בת סליא</h1>
            
            <div className='lottie' id="complete" ></div>

            <div className='texto'>
                Gracias por permitirnos ser parte de tus Tefilot. A partir de ahora todos nuestros alumnos su unirán a ellas. Que Hashem conteste todos los pedidos de tu corazón.
            </div>

            <div className='texto'>
                Recibirás un correo con tu confirmación y datos de tu pago.
            </div>

            <div className='texto'>
                Equipo Tehilatenu
            </div>

            <br />

            {/* <CButton color='warning' href='https://tehilatenu.ae.edu.mx'>Volver a Donar</CButton> */}

            <br />

            <center>
                <img src={LogoYeshiba} alt={"Active Code"} style={{ 'width': widthLogos, "height": "auto" }} />

                <br /> <br /> <br />

                <div className="container_row">
                    <div className="layer1">
                        <img id='mario' src={mario} alt={"Active Code"} style={{ 'width': "3%", "height": "auto" }} />
                    </div>
                    <div className="layer2">
                        <img src={logoActive} alt={"Active Code"} onClick={logoHandler} style={{ 'width': widthLogos, "height": "auto" }} />
                    </div>
                </div>
            </center>

            <br /><br />
        </div>
    )
}

export default Gracias
