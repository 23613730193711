import React, { useState, useLayoutEffect, useEffect, forwardRef } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CFormCheck, CFormInput, CButton, CContainer, CFormLabel, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react'
import { useSpring, animated } from 'react-spring'
import { isMobile, BrowserView, MobileView } from 'react-device-detect'
import axios from 'axios'
import Swal from "sweetalert2"
import Logo from '../assets/logo.png'
import useSound from "use-sound"
import WhatsAppWidget from "react-whatsapp-chat-widget"
import DatePicker from "react-datepicker"
import Odometer from 'react-odometerjs'
import dayjs from 'dayjs'
import getDay from "date-fns/getDay"

import CheckoutStripe from './CheckoutStripe'
import CheckoutPaypal from './CheckoutPaypal'
import CheckoutStripeUnico from './CheckoutStripeUnico'
import CheckoutPaypalUnico from './CheckoutPaypalUnico'

import marioJump from "../sounds/marioJump.mp3"
import "odometer/themes/odometer-theme-train-station.css"
// import "odometer/themes/odometer-theme-car.css"
// import "odometer/themes/odometer-theme-plaza.css"
// import "odometer/themes/odometer-theme-slot-machine.css"
import "react-whatsapp-chat-widget/index.css";
import "react-datepicker/dist/react-datepicker.css";
import './LandingDonar.css'

import LogoYeshiba from '../assets/logoYeshiba.png'
import logoActive from "../assets/LogoActiveAnimated.gif"
import mario from '../assets/mario.png'
import btnContinuar from "../assets/botones/btnContinuar.png"

import btn1Dia from "../assets/botones/btn1Dia1.png"
import btn1DiaCh from "../assets/botones/btn1DiaCh.png"
import btn1Nombre from "../assets/botones/btn1Nombre.png"
import btn1NombreCh from "../assets/botones/btn1NombreCh.png"
import btn3Nombre from "../assets/botones/btn3Nombre.png"
import btn3NombreCh from "../assets/botones/btn3NombreCh.png"
import btn5Nombre from "../assets/botones/btn5Nombre1.png"
import btn5NombreCh from "../assets/botones/btn5NombreCh.png"
import btnYoXIsrael from "../assets/botones/btnYoXIsrael.png"

function LandingDonar() {
  const [play] = useSound(marioJump)
  const [show, setShow] = useState(false)
  let [count, setCount] = useState(1)
  let [perakimLeidos, setPerakimLeidos] = useState()
  let [tehilimCompletos, setTehilimCompletos] = useState()
  let [totalNombres, setTotalNombres] = useState(0)
  let [totalPersonas, setTotalPersonas] = useState()
  let [unicoNombre, setUnicoNombre] = useState(false)
  let [unNombre, setUnNombre] = useState(true)
  let [tresNombre, setTresNombre] = useState(false)
  let [cincoNombre, setCincoNombre] = useState(false)
  let [unoPrueba, setUnoPrueba] = useState(false)
  let [cuantos, setCuantos] = useState(1)
  let [datosCompletos, setDatosCompletos] = useState(false)
  let [datosCompletosUnico, setDatosCompletosUnico] = useState(false)
  let [productoStripe, setProductoStripe] = useState("price_1MgFRpEWuCKQMrTvrMDe9DP8")
  let [productoPaypal, setProductoPaypal] = useState("P-0UY47575V7123523EMPDTB2Y")
  let [widthLogos, setWidthLogos] = useState('8%')
  let [widthBtn, setWidthBtn] = useState('30vw')
  let [widthTehilatenu, setWidthTehilatenu] = useState('40vw')
  let [widthbtnsOpciones, setWidthbtnsOpciones] = useState('20vw')
  let [useStripe, setUseStripe] = useState(true)
  let [usePaypal, setUsePaypal] = useState(false)

  let [abrej, setAbrej] = useState('639cd435eba1970016d6fbfb')
  let [errorAbrej, setErrorAbrej] = useState(false)

  let [nombre1, setNombre1] = useState('')
  let [nombre2, setNombre2] = useState('')
  let [nombre3, setNombre3] = useState('')
  let [nombre4, setNombre4] = useState('')
  let [nombre5, setNombre5] = useState('')
  let [nombre1Vacio, setNombre1Vacio] = useState()
  let [nombre2Vacio, setNombre2Vacio] = useState()
  let [nombre3Vacio, setNombre3Vacio] = useState()
  let [nombre4Vacio, setNombre4Vacio] = useState()
  let [nombre5Vacio, setNombre5Vacio] = useState()

  let [categoria1, setCategoria1] = useState('Selecciona')
  let [categoria2, setCategoria2] = useState('Selecciona')
  let [categoria3, setCategoria3] = useState('Selecciona')
  let [categoria4, setCategoria4] = useState('Selecciona')
  let [categoria5, setCategoria5] = useState('Selecciona')
  let [categoria1Vacio, setCategoria1Vacio] = useState()
  let [categoria2Vacio, setCategoria2Vacio] = useState()
  let [categoria3Vacio, setCategoria3Vacio] = useState()
  let [categoria4Vacio, setCategoria4Vacio] = useState()
  let [categoria5Vacio, setCategoria5Vacio] = useState()

  let [anonimo1, setAnonimo1] = useState(false)
  let [anonimo2, setAnonimo2] = useState(false)
  let [anonimo3, setAnonimo3] = useState(false)
  let [anonimo4, setAnonimo4] = useState(false)
  let [anonimo5, setAnonimo5] = useState(false)

  let [nombres, setNombres] = useState([])
  let [nombresUnico, setNombresUnico] = useState([])

  let [nombreCompleto, setNombreCompleto] = useState('')
  let [cel, setCel] = useState('')
  let [email, setEmail] = useState('')
  let [nombreCompletoVacio, setNombreCompletoVacio] = useState()
  let [emailVacio, setEmailVacio] = useState()
  let [celVacio, setCelVacio] = useState()

  let [nombreUnico, setNombreUnico] = useState('')
  let [nombreUnicoVacio, setNombreUnicoVacio] = useState()
  let [categoriaUnico, setCategoriaUnico] = useState('Selecciona')
  let [categoriaUnicoVacio, setCategoriaUnicoVacio] = useState()
  let [anonimoUnico, setAnonimoUnico] = useState('')
  let [fechaUnico, setFechaUnico] = useState("Selecciona la Fecha")
  let [fechaUnicoVacio, setFechaUnicoVacio] = useState()

  let [nombreCompletoUnico, setNombreCompletoUnico] = useState('')
  let [celUnico, setCelUnico] = useState('')
  let [emailUnico, setEmailUnico] = useState('')
  let [nombreCompletoUnicoVacio, setNombreCompletoUnicoVacio] = useState()
  let [emailUnicoVacio, setEmailUnicoVacio] = useState()
  let [celUnicoVacio, setCelUnicoVacio] = useState()
  let [startDate, setStartDate] = useState()

  // const navigate = useNavigate();
  const query = new URLSearchParams(location.search)
  // dias apagados del datepicker
  const isWeekday = (date) => { const day = getDay(date); return day !== 0 && day !== 6; };
  // css al input de calendario dia unico
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button style={fechaUnicoVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null} className="picker" onClick={onClick} ref={ref}>
      {fechaUnico == "Selecciona la Fecha" ? "Selecciona la Fecha" : dayjs(fechaUnico).format("DD/MM/YYYY")}
    </button>
  ));

  function logoHandler() {
    let temp = count

    if (count == 14) {
      play()
      document.getElementById('mario').classList.add("mario")
      setTimeout(() => { document.getElementById('mario').classList.remove("mario") }, 600);
      setCount(1)
    }
    else { temp++; setCount(temp) }
  }

  function handleSubmit() {
    let message = ''
    if (nombreCompleto == '') { message = 'l donador estan incompletos'; setNombreCompletoVacio("vacio") }
    if (email == '') { message = 'l donador estan incompletos'; setEmailVacio("vacio") }
    if (cel == '') { message = 'l donador estan incompletos'; setCelVacio("vacio") }

    if (cuantos > 0) {
      if (nombre1 == '') { message = ' los nombres no estan completos'; setNombre1Vacio("vacio") }
      if (categoria1 == 'Selecciona') { message = ' los nombres no estan completos'; setCategoria1Vacio("vacio") }
    }
    if (cuantos > 1) {
      if (nombre2 == '') { message = ' los nombres no estan completos'; setNombre2Vacio("vacio") }
      if (categoria2 == 'Selecciona') { message = ' los nombres no estan completos'; setCategoria2Vacio("vacio") }
      if (nombre3 == '') { message = ' los nombres no estan completos'; setNombre3Vacio("vacio") }
      if (categoria3 == 'Selecciona') { message = ' los nombres no estan completos'; setCategoria3Vacio("vacio") }
    }
    if (cuantos > 3) {
      if (nombre4 == '') { message = ' los nombres no estan completos'; setNombre4Vacio("vacio") }
      if (categoria4 == 'Selecciona') { message = ' los nombres no estan completos'; setCategoria4Vacio("vacio") }
      if (nombre5 == '') { message = ' los nombres no estan completos'; setNombre5Vacio("vacio") }
      if (categoria5 == 'Selecciona') { message = ' los nombres no estan completos'; setCategoria5Vacio("vacio") }
    }

    Swal.fire(`Los datos de${message}, por favor llena todos los campos.`)
  }

  function handleSubmit2() {
    let message = ''

    if (nombreCompletoUnico == '') { message = 'l donador estan incompletos'; setNombreCompletoUnicoVacio("vacio") }
    if (emailUnico == '') { message = 'l donador estan incompletos'; setEmailUnicoVacio("vacio") }
    if (celUnico == '') { message = 'l donador estan incompletos'; setCelUnicoVacio("vacio") }
    if (nombreUnico == '') { message = ' los nombres no estan completos'; setNombreUnicoVacio("vacio") }
    if (categoriaUnico == 'Selecciona') { message = ' los nombres no estan completos'; setCategoriaUnicoVacio("vacio") }
    if (fechaUnico == 'Selecciona la Fecha') { message = ' los nombres no estan completos'; setFechaUnicoVacio("vacio") }

    Swal.fire(`Los datos de${message}, por favor llena todos los campos.`)
  }

  function seleccion(id) {
    let id1
    let id2
    let id3
    let id4

    if (id == "1Nombre") { id1 = "1Nombre"; id2 = "3Nombre"; id3 = "5Nombre"; id4 = "1Dia" }
    if (id == "3Nombre") { id1 = "3Nombre"; id2 = "1Nombre"; id3 = "5Nombre"; id4 = "1Dia" }
    if (id == "5Nombre") { id1 = "5Nombre"; id2 = "1Nombre"; id3 = "3Nombre"; id4 = "1Dia" }
    if (id == "1Dia") { id1 = "1Dia"; id2 = "1Nombre"; id3 = "3Nombre"; id4 = "5Nombre" }

    document.getElementById(id1).style.setProperty('opacity', '1')
    // document.getElementById(id1).style.setProperty('box-shadow', 'rgba(161, 153, 130, 1) 0 -6px 8px inset')

    document.getElementById(id2).style.setProperty('opacity', '.5')
    // document.getElementById(id2).style.setProperty('box-shadow', 'rgba(161, 153, 130,.2) 0 -6px 8px inset')

    document.getElementById(id3).style.setProperty('opacity', '.5')
    // document.getElementById(id3).style.setProperty('box-shadow', 'rgba(161, 153, 130,.2) 0 -6px 8px inset')

    document.getElementById(id4).style.setProperty('opacity', '.5')
    // document.getElementById(id4).style.setProperty('box-shadow', 'rgba(161, 153, 130,.2) 0 -6px 8px inset')
  }

  function resetSeleccion() {
    setCuantos(1)

    setFechaUnico("Selecciona la Fecha")
    setNombreUnico('')
    setCategoriaUnico('Selecciona')
    setAnonimoUnico('')
    setNombreCompletoUnico('')
    setCelUnico('')
    setEmailUnico('')

    setFechaUnicoVacio()
    setNombreUnicoVacio()
    setCategoriaUnicoVacio()
    setNombreCompletoUnicoVacio()
    setCelUnicoVacio()
    setEmailUnicoVacio()

    document.getElementById("1Nombre").style.setProperty('opacity', '1')
    // document.getElementById("1Nombre").style.setProperty('box-shadow', 'rgba(161, 153, 130, 1) 0 -6px 8px inset')

    document.getElementById("3Nombre").style.setProperty('opacity', '1')
    // document.getElementById("3Nombre").style.setProperty('box-shadow', 'rgba(161, 153, 130, 1) 0 -6px 8px inset')

    document.getElementById("5Nombre").style.setProperty('opacity', '1')
    // document.getElementById("5Nombre").style.setProperty('box-shadow', 'rgba(161, 153, 130, 1) 0 -6px 8px inset')

    document.getElementById("1Dia").style.setProperty('opacity', '1')
    // document.getElementById("1Dia").style.setProperty('box-shadow', 'rgba(161, 153, 130, 1) 0 -6px 8px inset')
  }

  useLayoutEffect(() => {
    if (isMobile) { setWidthLogos('30vw'); setWidthBtn('100vw'); setWidthTehilatenu('70vw'); setWidthbtnsOpciones('40vw') }

    if (query.get("codigofamilia")) {
      axios.get(`${process.env.REACT_APP_BackURL}/getAllAbrejim`)
        .then((response) => {
          let existe = false

          for (let i in response.data.data) { if (response.data.data[i].codigoFamilia == query.get("codigofamilia")) { existe = true } }

          if (existe) { setAbrej(query.get("codigofamilia")) }
          else { setErrorAbrej(true) }
        })

        .catch((err) => { console.log(err) })
    }

    axios.get(`${process.env.REACT_APP_BackURL}/getAllPerakim`)
      .then((response) => { setPerakimLeidos(response.data.data[0].perakimLeidos); setTotalPersonas(response.data.data[0].personasLeyendo); setTehilimCompletos(response.data.data[0].tehilimCompletos) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getallsuscripciones`)
      .then((response) => {
        let temp = []
        response.data.data.map((item) => { temp.push(item.nombres.length) })

        setTotalNombres(temp.reduce((a, b) => a + b, 0))
      })

      .catch((err) => { console.log(err) })
  }, [])

  useEffect(() => {
    let advance = true
    let temp = []

    let uno = [{ "nombre": nombre1, "categoria": categoria1, "anonimo": anonimo1 }]
    let tres = [{ "nombre": nombre1, "categoria": categoria1, "anonimo": anonimo1 }, { "nombre": nombre2, "categoria": categoria2, "anonimo": anonimo2 }, { "nombre": nombre3, "categoria": categoria3, "anonimo": anonimo3 }]
    let cinco = [{ "nombre": nombre1, "categoria": categoria1, "anonimo": anonimo1 }, { "nombre": nombre2, "categoria": categoria2, "anonimo": anonimo2 }, { "nombre": nombre3, "categoria": categoria3, "anonimo": anonimo3 }, { "nombre": nombre4, "categoria": categoria4, "anonimo": anonimo4 }, { "nombre": nombre5, "categoria": categoria5, "anonimo": anonimo5 }]

    if (unNombre) { temp = uno }
    if (tresNombre) { temp = tres }
    if (cincoNombre) { temp = cinco }
    if (unoPrueba) { temp = uno }

    if (!nombreCompleto || !email || !/\S+@\S+\.\S+/.test(email) || !cel || cel.length < 10) {
      if (nombreCompleto == undefined) { setNombreCompletoVacio("vacio") }
      if (email == undefined) { setEmailVacio("vacio") }
      if (cel == undefined) { setCelVacio("vacio") }

      advance = false
    }

    if (cuantos > 0) {
      if (!nombre1 || categoria1 == 'Selecciona') {
        if (nombre1 == undefined) { setNombre1Vacio("vacio") }
        // if (categoria1 == 'Selecciona') { setCategoria1Vacio("vacio") }

        advance = false
      }
    }

    if (cuantos > 1) {
      if (!nombre2 || categoria2 == 'Selecciona' || !nombre3 || categoria3 == 'Selecciona') {
        if (nombre2 == undefined) { setNombre2Vacio("vacio") }
        // if (categoria2Vacio == 'Selecciona') { setCategoria2Vacio("vacio") }
        if (nombre3 == undefined) { setNombre3Vacio("vacio") }
        // if (categoria3 == 'Selecciona') { setCategoria3Vacio("vacio") }

        advance = false
      }
    }

    if (cuantos > 3) {
      if (!nombre4 || categoria4 == 'Selecciona' || !nombre5 || categoria5 == 'Selecciona') {
        if (nombre4 == undefined) { setNombre4Vacio("vacio") }
        // if (categoria4Vacio == 'Selecciona') { setCategoria4Vacio("vacio") }
        if (nombre5 == undefined) { setNombre5Vacio("vacio") }
        // if (categoria5 == 'Selecciona') { setCategoria5Vacio("vacio") }

        advance = false
      }
    }

    setNombres(temp)

    if (advance) { setDatosCompletos(true) }
    else { setDatosCompletos(false) }
  }, [nombreCompleto, cel, email, unNombre, tresNombre, cincoNombre, nombre1, categoria1, anonimo1, nombre2, categoria2, anonimo2, nombre3, categoria3, anonimo3, nombre4, categoria4, anonimo4, nombre5, categoria5, anonimo5])

  useEffect(() => {
    if (show) {
      let advance = true
      // let temp = []

      let temp = [{ "nombre": nombreUnico, "categoria": categoriaUnico, "anonimo": anonimoUnico }]

      if (!nombreCompletoUnico || !emailUnico || !/\S+@\S+\.\S+/.test(emailUnico) || !celUnico || celUnico.length < 10 || fechaUnico == "Selecciona la Fecha") {
        if (nombreCompletoUnico == undefined) { setNombreCompletoUnicoVacio("vacio") }
        if (emailUnico == undefined) { setEmailUnicoVacio("vacio") }
        if (celUnico == undefined) { setCelUnicoVacio("vacio") }

        advance = false
      }

      setNombresUnico(temp)

      if (advance) { setDatosCompletosUnico(true) }
      else { setDatosCompletosUnico(false) }
    }
  }, [show, nombreCompletoUnico, celUnico, emailUnico, nombreUnico, categoriaUnico, anonimoUnico])

  return (
    <>
      <CModal size='lg' visible={show} onClose={() => { setShow(false); resetSeleccion() }}>
        <CModalHeader style={{ backgroundColor: "rgba(169, 186, 166, 0.4)" }} onClose={() => { setShow(false); resetSeleccion() }}>
          {/* <CModalTitle>1 Día especial de Tehilim </CModalTitle> */}
        </CModalHeader>

        <CModalBody className='centrado'>
          <h3>1 día especial de Tehilim</h3>
          <br />
          <h4>Nos unimos a tus Tefilot el día del mes que tú elijas <br />
            El Tehilim de todos nuestros alumn@s lo dedicaremos a ti y a tu seres queridos concluyendo con una Tefila especial que hará el Jajam Abraham Shabot. <br />
            Costo: $1,800</h4>

          <br />

          <div className='fondoCuadrito container'>

            <br />

            <div className="row">
              <div className="col-sm-6">
                <CFormInput type="text" id="exampleFormControlInput1" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                  value={nombreUnico}
                  style={nombreUnicoVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => { setNombreUnico(e.target.value); e.target.value != '' ? setNombreUnicoVacio() : setNombreUnicoVacio('vacio') }}
                // onChange={(e) => { setNombreUnico(e.target.value) }}
                />

                <br />
              </div>

              <div className="col-sm-4">
                <CFormLabel htmlFor="exampleDropdownFormEmail1">Categoría</CFormLabel>

                <br />

                <CDropdown>
                  {/* <CDropdownToggle color="light">{categoria1}</CDropdownToggle> */}
                  {/* <CDropdownMenu onClick={(e) => { setCategoria1(e.target.text) }}> */}
                  <CDropdownToggle color={categoriaUnicoVacio == 'vacio' ? 'danger' : 'light'}>{categoriaUnico}</CDropdownToggle>
                  <CDropdownMenu onClick={(e) => { setCategoriaUnico(e.target.text); setCategoriaUnicoVacio() }}>
                    <CDropdownItem value='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                    <CDropdownItem value='Refua Shelema'>Refua Shelema</CDropdownItem>
                    <CDropdownItem value='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                    <CDropdownItem value='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                    <CDropdownItem value='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                    <CDropdownItem value='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                    <CDropdownItem value='Mazal Tob'>Mazal Tob</CDropdownItem>
                    <CDropdownItem value='Leda Kala'>Leda Kala</CDropdownItem>
                    <CDropdownItem value='Besorot Tobot'>Besorot Tobot</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </div>

              <div className="col-sm-2">

                {isMobile ? <br /> : null}

                <CFormLabel htmlFor="exampleDropdownFormEmail1">Anónimo</CFormLabel>
                <br />

                <CFormCheck checked={anonimoUnico} id="flexCheckDefault" onChange={() => { anonimoUnico ? setAnonimoUnico(false) : setAnonimoUnico(true) }} />
              </div>

              <CFormLabel htmlFor="exampleDropdownFormEmail1">Fecha</CFormLabel>
              <DatePicker customInput={<ExampleCustomInput />} filterDate={isWeekday} includeDateIntervals={[{ start: new Date(), end: new Date(dayjs().add(1, 'year')) },]} placeholderText={fechaUnico == "Selecciona la Fecha" ? "Selecciona la Fecha" : dayjs(fechaUnico).format("DD/MM/YYYY")} onChange={(date) => { setFechaUnico(date); setFechaUnicoVacio() }} />

              <br /><br />
            </div>
          </div>

          <br /><br />

          <div className='fondoCuadrito'>

            <br />

            <p>Ingresa tus datos</p>

            <div className='container'>
              <div className='row'>
                <div className='col-sm-6'>
                  {/* <CFormLabel htmlFor="exampleDropdownFormEmail5">Nombre Completo</CFormLabel> */}
                  <CFormInput type="text" id="exampleFormControlInput1" placeholder="Nombre Completo" aria-describedby="exampleFormControlInputHelpInline"
                    style={nombreCompletoUnicoVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                    onChange={(e) => { setNombreCompletoUnico(e.target.value); e.target.value != '' ? setNombreCompletoUnicoVacio() : setNombreCompletoUnicoVacio('vacio') }}
                  />

                  <br />
                </div>

                <div className='col-sm-3'>
                  {/* <CFormLabel htmlFor="exampleDropdownFormEmail5">{celVacio === "vacio" ? "Celular (10 digitos)" : "Celular"}</CFormLabel> */}
                  <CFormInput type="number" id="exampleFormControlInput1" placeholder={celUnicoVacio === "vacio" ? "Celular (10 digitos)" : "Celular"} aria-describedby="exampleFormControlInputHelpInline"
                    style={celUnicoVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                    onChange={(e) => {
                      setCelUnico(e.target.value);

                      e.target.value == "" ?
                        setCelUnicoVacio("vacio")
                        :
                        e.target.value.length < 10 ? setCelUnicoVacio("vacio")
                          :
                          setCelUnicoVacio()
                    }}
                  />
                  <br />
                </div>

                <div className='col-sm-3'>
                  {/* <CFormLabel htmlFor="exampleDropdownFormEmail5">Correo Eletrónico</CFormLabel> */}
                  <CFormInput type="email" id="exampleFormControlInput1" placeholder='Correo Eletrónico' aria-describedby="exampleFormControlInputHelpInline" style={emailUnicoVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                    onChange={(e) => {
                      setEmailUnico(e.target.value)

                      e.target.value == '' ?
                        setEmailUnicoVacio('vacio')
                        :
                        !/\S+@\S+\.\S+/.test(e.target.value) ?
                          setEmailUnicoVacio('vacio')
                          :
                          setEmailUnicoVacio()
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <br /><br />

          <div className="container">
            <div className="row">

              <div className="col">
                {
                  datosCompletosUnico ?
                    <>
                      <div className='fondoCuadrito'>
                        <br />

                        <p>Selecciona tu forma de pago</p>

                        <div className='row'>
                          <div className="col">
                            <CFormCheck checked={useStripe} id="flexCheckDefault" onChange={(e) => { if (e.target.checked) { setUseStripe(true); setUsePaypal(false) } }} />

                            <br />

                            <p>Tarjeta de Crédito / Débito</p>
                          </div>

                          <div className='col'>
                            <CFormCheck checked={usePaypal} id="flexCheckDefault" onChange={(e) => { if (e.target.checked) { setUseStripe(false); setUsePaypal(true) } }} />

                            <br />

                            <p>PayPal</p>
                          </div>
                        </div>

                        <div className='row' style={{ padding: '4%' }}>
                          {
                            useStripe ?
                              // <><br /><CheckoutStripe codigoFamilia={query.get("codigofamilia")} nombreCompleto={nombreCompleto} email={email} cel={cel} nombres={JSON.stringify(nombres)} /><br /><br /></>
                              <><br /><CheckoutStripeUnico codigoFamilia={query.get("codigofamilia")} nombreCompletoUnico={nombreCompletoUnico} emailUnico={emailUnico} celUnico={celUnico} nombresUnico={JSON.stringify(nombresUnico)} fechaUnico={fechaUnico} /><br /><br /></>

                              :
                              // <><CheckoutPaypal codigoFamilia={query.get("codigofamilia")} nombreCompleto={nombreCompleto} email={email} cel={cel} nombres={JSON.stringify(nombres)} /></>
                              <><CheckoutPaypalUnico codigoFamilia={query.get("codigofamilia")} nombreCompletoUnico={nombreCompletoUnico} emailUnico={emailUnico} celUnico={celUnico} nombresUnico={JSON.stringify(nombresUnico)} fechaUnico={fechaUnico} /></>
                          }
                        </div>

                      </div>
                    </>
                    :
                    <>
                      {/* <CButton className='btnStripe' color='warning' id="checkout-and-portal-button" type="submit" onClick={() => { handleSubmit() }}>Continuar</CButton> */}
                      <img className="img-fluid" style={{ height: 'auto', width: widthBtn }} src={btnContinuar} alt="" onClick={() => { handleSubmit2() }}></img>
                    </>
                }
              </div>
            </div>
          </div>

        </CModalBody>

      </CModal>

      <div className='centrado'>

        <img style={{ "width": widthTehilatenu, "height": "auto" }} src={Logo} alt="" />

        <br />

        <h1>לעילוי נשמת זכייה בת סליא</h1>

        <br />

        <br />
        {errorAbrej ? <><p style={{ 'color': 'red', 'fontWeight': 'bold' }}>La referencia de abrej es invalida, por favor intenta de nuevo.</p><br /><br /></> : null}

        <div className='fondoCuadrito'>

          <div className="container overflow-hidden text-center">

            <p>En Tehilatenu actualmente tenemos:</p>
            <br />

            <div className="row">
              <div className="col-md">
                <center>
                  Perakim de Tehilim leidos
                  <div className="block">
                    <Odometer value={perakimLeidos} format="d" />
                  </div>
                </center>
                <br />
              </div>

              <div className="col-md">
                <center>
                  Cadenas de Tehilim
                  <div className="block">
                    <Odometer value={tehilimCompletos} format="d" />
                  </div>
                </center>
                <br />
              </div>

              <div className="col-md">
                <center>
                  Niñ@s rezando diariamente
                  <div className="block">
                    <Odometer value={totalPersonas} format="d" />
                  </div>
                  <br />
                </center>
              </div>

              {/*<p>Tenemos un total de {totalNombres} nombres por los cuales se lee el tehilim.</p> */}
            </div>
          </div>
        </div>

        <br /><br />

        <div className='fondoCuadrito'>
          <br />

          <p>¡UNAMOS NUESTRAS TEFILOT!</p>
          <p>¿Te interesa que {totalPersonas} niñ@s pidan diariamente por ti?</p>
          <p> Elige tu opción</p>
          <br />

          <div className='row'>
            {/* <div className="col-lg btns" style={{ marginBottom: "5%" }}> */}
            {/* <CFormCheck checked={unNombre} id="flexCheckDefault" onChange={(e) => { if (e.target.checked) { setUnoPrueba(false); setUnNombre(true); setTresNombre(false); setCincoNombre(false); setCuantos(1); setProductoStripe("price_1MgFRpEWuCKQMrTvrMDe9DP8"); setProductoPaypal('P-0UY47575V7123523EMPDTB2Y') } }} />

              <p>1 Nombre</p>

              <p>$500 mensuales</p> */}

            {/* <button id="1Nombre" className="botonNombres" role="button" onClick={() => { seleccion("1Nombre"); setUnoPrueba(false); setUnNombre(true); setTresNombre(false); setCincoNombre(false); setUnicoNombre(false); setCuantos(1); setProductoStripe("price_1MgFRpEWuCKQMrTvrMDe9DP8"); setProductoPaypal('P-0UY47575V7123523EMPDTB2Y') }}>
                1 Nombre

                <div style={{ color: "red", fontSize: "1.5rem" }}>$250</div>

                mensuales

              </button> */}

            <div className="col-lg" style={{ marginBottom: "5%" }} id="btns">
              <img id="1Nombre" style={{ "width": widthbtnsOpciones, "height": "auto" }} src={!isMobile ? btn1Nombre : btn1NombreCh} alt="" onClick={() => { seleccion("1Nombre"); setUnoPrueba(false); setUnNombre(true); setTresNombre(false); setCincoNombre(false); setUnicoNombre(false); setCuantos(1); setProductoStripe("price_1MgFRpEWuCKQMrTvrMDe9DP8"); setProductoPaypal('P-0UY47575V7123523EMPDTB2Y') }} />
            </div>
            {/* </div> */}

            {/* <div className='col-lg' style={{ marginBottom: "5%" }}> */}
            {/* <CFormCheck checked={tresNombre} id="flexCheckDefault" onChange={(e) => { if (e.target.checked) { setUnoPrueba(false); setUnNombre(false); setTresNombre(true); setCincoNombre(false); setCuantos(3); setProductoStripe("price_1MPuDuEWuCKQMrTv8Je2qw1E"); setProductoPaypal('P-5LE6335074014160CMPDTCTI') } }} />

              <p>3 Nombres</p>

              <p>$1,000 mensuales</p> */}

            {/* <button id="3Nombre" className="botonNombres" role="button" onClick={() => { seleccion("3Nombre"), setUnoPrueba(false); setUnNombre(false); setTresNombre(true); setCincoNombre(false); setUnicoNombre(false); setCuantos(3); setProductoStripe("price_1MgFUNEWuCKQMrTvjCT4JUxn"); setProductoPaypal('P-5LE6335074014160CMPDTCTI') }}>
                3 Nombres
                <div style={{ color: "red", fontSize: "1.5rem" }}>$400</div>
                mensuales
              </button> */}

            <div className="col-lg" style={{ marginBottom: "5%" }} id="btns">
              <img id="3Nombre" style={{ "width": widthbtnsOpciones, "height": "auto" }} src={!isMobile ? btn3Nombre : btn3NombreCh} alt="" onClick={() => { seleccion("3Nombre"), setUnoPrueba(false); setUnNombre(false); setTresNombre(true); setCincoNombre(false); setUnicoNombre(false); setCuantos(3); setProductoStripe("price_1MgFUNEWuCKQMrTvjCT4JUxn"); setProductoPaypal('P-5LE6335074014160CMPDTCTI') }} />
            </div>
            {/* </div> */}

            {/* <div className='col-lg' style={{ marginBottom: "5%" }}> */}
            {/* <CFormCheck checked={cincoNombre} id="flexCheckDefault" onChange={(e) => { if (e.target.checked) { setUnoPrueba(false); setUnNombre(false); setTresNombre(false); setCincoNombre(true); setCuantos(5); setProductoStripe("price_1MPuEuEWuCKQMrTvXGNQIpjZ"); setProductoPaypal('P-1R50058167195834TMPDTDHA') } }} />

              <p>5 Nombres</p>

              <p>$1,500 mensuales</p> */}

            {/* <button id="5Nombre" className="botonNombres" role="button" onClick={() => { seleccion("5Nombre"); setUnoPrueba(false); setUnNombre(false); setTresNombre(false); setCincoNombre(true); setCuantos(5); setUnicoNombre(false); setProductoStripe("price_1MgFWvEWuCKQMrTvEtx4mUGK"); setProductoPaypal('P-1R50058167195834TMPDTDHA') }}>
                5 Nombres
                <div style={{ color: "red", fontSize: "1.5rem" }}>$500</div>
                mensuales
                <p style={{fontSize: ".5rem"}}>mensuales</p>
              </button> */}

            <div className="col-lg" style={{ marginBottom: "5%" }} id="btns">
              <img id="5Nombre" style={{ "width": widthbtnsOpciones, "height": "auto" }} src={!isMobile ? btn5Nombre : btn5NombreCh} alt="" onClick={() => { seleccion("5Nombre"); setUnoPrueba(false); setUnNombre(false); setTresNombre(false); setCincoNombre(true); setCuantos(5); setUnicoNombre(false); setProductoStripe("price_1MgFWvEWuCKQMrTvEtx4mUGK"); setProductoPaypal('P-1R50058167195834TMPDTDHA') }} />
            </div>
            {/* </div> */}

            {/* <div className='col-lg' style={{ marginBottom: "5%" }}> */}
            {/* <CFormCheck checked={cincoNombre} id="flexCheckDefault" onChange={(e) => { if (e.target.checked) { setUnoPrueba(false); setUnNombre(false); setTresNombre(false); setCincoNombre(true); setCuantos(5); setProductoStripe("price_1MPuEuEWuCKQMrTvXGNQIpjZ"); setProductoPaypal('P-1R50058167195834TMPDTDHA') } }} />

              <p>5 Nombres</p>

              <p>$1,500 mensuales</p> */}

            {/* <button id="1Dia" className="botonNombres" role="button" onClick={() => { setShow(true); seleccion("1Dia") }}>
                1 Dia
                <div style={{ color: "red", fontSize: "1.5rem" }}>$1,800</div>
                Unico pago
              </button> */}

            <div className="col-lg" style={{ marginBottom: "5%" }} id="btns">
              <img id="1Dia" style={{ "width": widthbtnsOpciones, "height": "auto" }} src={!isMobile ? btn1Dia : btn1DiaCh} alt="" onClick={() => { setShow(true); seleccion("1Dia") }} />
            </div>
            {/* </div> */}

            {/* <div className="col">
              <CFormCheck checked={unoPrueba} id="flexCheckDefault" onChange={(e) => { if (e.target.checked) { setUnoPrueba(true); setUnNombre(false); setTresNombre(false); setCincoNombre(false); setCuantos(1); setProductoStripe("price_1MPuFdEWuCKQMrTvLd77vJ98"); setProductoPaypal('P-77041520G6435432CMPDTD2Q') } }} />

              <p>Test</p>

              <p>$1 al dia</p>

            </div> */}

            <div className="col-lg" style={{ marginBottom: "5%" }} id="btns">
              <a href="https://tehilatenu.ae.edu.mx/gift?gift=YOXISRAEL">
                <img id="1Dia" style={{ "width": widthbtnsOpciones, "height": "auto" }} src={btnYoXIsrael} alt="" onClick={() => { window.location.href('https://tehilatenu.ae.edu.mx/cuenta') }} />
              </a>
            </div>

          </div>

          <br />

          {
            cuantos > 0 ?
              <>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6">
                      <CFormInput type="text" id="exampleFormControlInput1" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                        value={nombre1}
                        style={nombre1Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                        onChange={(e) => { setNombre1(e.target.value); e.target.value != '' ? setNombre1Vacio() : setNombre1Vacio('vacio') }}
                      // onChange={(e) => { setNombre1(e.target.value) }}
                      />
                      <br />
                    </div>

                    <div className="col-sm-4">
                      <CFormLabel htmlFor="exampleDropdownFormEmail1">Categoría</CFormLabel>

                      <br />

                      <CDropdown>
                        {/* <CDropdownToggle color="light">{categoria1}</CDropdownToggle> */}
                        {/* <CDropdownMenu onClick={(e) => { setCategoria1(e.target.text) }}> */}
                        <CDropdownToggle color={categoria1Vacio == 'vacio' ? 'danger' : 'light'}>{categoria1}</CDropdownToggle>
                        <CDropdownMenu onClick={(e) => { setCategoria1(e.target.text); setCategoria1Vacio() }}>
                          <CDropdownItem value='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                          <CDropdownItem value='Refua Shelema'>Refua Shelema</CDropdownItem>
                          <CDropdownItem value='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                          <CDropdownItem value='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                          <CDropdownItem value='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                          <CDropdownItem value='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                          <CDropdownItem value='Agradecimiento'>Agradecimiento</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>

                    <div className="col-sm-2">

                      {isMobile ? <br /> : null}

                      <CFormLabel htmlFor="exampleDropdownFormEmail1">Anónimo</CFormLabel>

                      <br />

                      <CFormCheck checked={anonimo1} id="flexCheckDefault" onChange={() => { anonimo1 ? setAnonimo1(false) : setAnonimo1(true) }} />
                    </div>
                  </div>
                  <br />
                </div>

              </>

              : null
          }

          {
            cuantos > 1 ?
              <>
                <div className='container'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <CFormInput type="text" id="exampleFormControlInput2" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                        value={nombre2}
                        style={nombre2Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                        onChange={(e) => { setNombre2(e.target.value); e.target.value != '' ? setNombre2Vacio() : setNombre2Vacio('vacio') }}
                      // onChange={(e) => { setNombre2(e.target.value) }}
                      />
                      <br />
                    </div>

                    <div className='col-sm-4'>
                      <CFormLabel htmlFor="exampleDropdownFormEmail2">Categoria</CFormLabel>

                      <br />

                      <CDropdown>
                        {/* <CDropdownToggle color="light">{categoria2}</CDropdownToggle> */}
                        {/* <CDropdownMenu onClick={(e) => { setCategoria2(e.target.text) }}> */}
                        <CDropdownToggle color={categoria2Vacio == 'vacio' ? 'danger' : 'light'}>{categoria2}</CDropdownToggle>
                        <CDropdownMenu onClick={(e) => { setCategoria2(e.target.text); setCategoria2Vacio() }}>
                          <CDropdownItem value='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                          <CDropdownItem value='Refua Shelema'>Refua Shelema</CDropdownItem>
                          <CDropdownItem value='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                          <CDropdownItem value='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                          <CDropdownItem value='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                          <CDropdownItem value='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                          <CDropdownItem value='Agradecimiento'>Agradecimiento</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>

                    <div className='col-sm-2'>

                      {isMobile ? <br /> : null}

                      <CFormLabel htmlFor="exampleDropdownFormEmail3">Anonimo</CFormLabel>

                      <br />

                      <CFormCheck checked={anonimo2} id="flexCheckDefault" onChange={() => { anonimo2 ? setAnonimo2(false) : setAnonimo2(true) }} />
                    </div>
                  </div>

                  <br />

                </div>

                <div className='container'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <CFormInput type="text" id="exampleFormControlInput3" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                        value={nombre3}
                        style={nombre3Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                        onChange={(e) => { setNombre3(e.target.value); e.target.value != '' ? setNombre3Vacio() : setNombre3Vacio('vacio') }}
                      // onChange={(e) => { setNombre3(e.target.value) }}
                      />
                      <br />
                    </div>

                    <div className='col-sm-4'>
                      <CFormLabel htmlFor="exampleDropdownFormEmail3">Categoria</CFormLabel>

                      <br />

                      <CDropdown>
                        {/* <CDropdownToggle color="light">{categoria3}</CDropdownToggle>
                            <CDropdownMenu onClick={(e) => { setCategoria3(e.target.text) }}> */}
                        <CDropdownToggle color={categoria3Vacio == 'vacio' ? 'danger' : 'light'}>{categoria3}</CDropdownToggle>
                        <CDropdownMenu onClick={(e) => { setCategoria3(e.target.text); setCategoria3Vacio() }}>
                          <CDropdownItem value='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                          <CDropdownItem value='Refua Shelema'>Refua Shelema</CDropdownItem>
                          <CDropdownItem value='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                          <CDropdownItem value='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                          <CDropdownItem value='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                          <CDropdownItem value='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                          <CDropdownItem value='Agradecimiento'>Agradecimiento</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>

                    <div className='col-sm-2'>

                      {isMobile ? <br /> : null}

                      <CFormLabel htmlFor="exampleDropdownFormEmail3">Anonimo</CFormLabel>

                      <br />

                      <CFormCheck checked={anonimo3} id="flexCheckDefault" onChange={() => { anonimo3 ? setAnonimo3(false) : setAnonimo3(true) }} />
                    </div>
                  </div>
                  <br />
                </div>
              </>

              : null
          }

          {
            cuantos > 3 ?
              <>
                <div className='container'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <CFormInput type="text" id="exampleFormControlInput4" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                        value={nombre4}
                        style={nombre4Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                        onChange={(e) => { setNombre4(e.target.value); e.target.value != '' ? setNombre4Vacio() : setNombre4Vacio('vacio') }}
                      // onChange={(e) => { setNombre4(e.target.value) }}
                      />
                      <br />
                    </div>

                    <div className='col-sm-4'>
                      <CFormLabel htmlFor="exampleDropdownFormEmail4">Categoria</CFormLabel>

                      <br />

                      <CDropdown>
                        {/* <CDropdownToggle color="light">{categoria4}</CDropdownToggle>
                    <CDropdownMenu onClick={(e) => { setCategoria4(e.target.text) }}> */}
                        <CDropdownToggle color={categoria4Vacio == 'vacio' ? 'danger' : 'light'}>{categoria4}</CDropdownToggle>
                        <CDropdownMenu onClick={(e) => { setCategoria4(e.target.text); setCategoria4Vacio() }}>
                          <CDropdownItem value='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                          <CDropdownItem value='Refua Shelema'>Refua Shelema</CDropdownItem>
                          <CDropdownItem value='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                          <CDropdownItem value='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                          <CDropdownItem value='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                          <CDropdownItem value='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                          <CDropdownItem value='Agradecimiento'>Agradecimiento</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>

                    <div className='col-sm-2'>

                      {isMobile ? <br /> : null}

                      <CFormLabel htmlFor="exampleDropdownFormEmail4">Anonimo</CFormLabel>

                      <br />

                      <CFormCheck checked={anonimo4} id="flexCheckDefault" onChange={() => { anonimo4 ? setAnonimo4(false) : setAnonimo4(true) }} />
                    </div>
                  </div>
                  <br />
                </div>

                <div className='container'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <CFormInput type="text" id="exampleFormControlInput5" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                        value={nombre5}
                        style={nombre5Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                        onChange={(e) => { setNombre5(e.target.value); e.target.value != '' ? setNombre5Vacio() : setNombre5Vacio('vacio') }}
                      // onChange={(e) => { setNombre5(e.target.value) }}
                      />
                      <br />
                    </div>

                    <div className='col-sm-4'>
                      <CFormLabel htmlFor="exampleDropdownFormEmail5">Categoria</CFormLabel>

                      <br />

                      <CDropdown>
                        {/* <CDropdownToggle color="light">{categoria5}</CDropdownToggle>
                    <CDropdownMenu onClick={(e) => { setCategoria5(e.target.text) }}> */}
                        <CDropdownToggle color={categoria5Vacio == 'vacio' ? 'danger' : 'light'}>{categoria5}</CDropdownToggle>
                        <CDropdownMenu onClick={(e) => { setCategoria5(e.target.text); setCategoria5Vacio() }}>
                          <CDropdownItem value='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                          <CDropdownItem value='Refua Shelema'>Refua Shelema</CDropdownItem>
                          <CDropdownItem value='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                          <CDropdownItem value='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                          <CDropdownItem value='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                          <CDropdownItem value='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                          <CDropdownItem value='Agradecimiento'>Agradecimiento</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>

                    <div className='col-sm-2'>

                      {isMobile ? <br /> : null}

                      <CFormLabel htmlFor="exampleDropdownFormEmail5">Anonimo</CFormLabel>

                      <br />

                      <CFormCheck checked={anonimo5} id="flexCheckDefault" onChange={() => { anonimo5 ? setAnonimo5(false) : setAnonimo5(true) }} />
                    </div>
                  </div>
                  <br />
                </div>

              </>

              : null
          }
        </div>

        <br /><br />

        <div className='fondoCuadrito'>

          <br />

          <p>Ingresa tus datos</p>

          <div className='container'>
            <div className='row'>
              <div className='col-sm-6'>
                {/* <CFormLabel htmlFor="exampleDropdownFormEmail5">Nombre Completo</CFormLabel> */}
                <CFormInput type="text" id="exampleFormControlInput1" placeholder="Nombre Completo" aria-describedby="exampleFormControlInputHelpInline"
                  style={nombreCompletoVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => { setNombreCompleto(e.target.value); e.target.value != '' ? setNombreCompletoVacio() : setNombreCompletoVacio('vacio') }}
                />
                <br />
              </div>

              <div className='col-sm-3'>
                {/* <CFormLabel htmlFor="exampleDropdownFormEmail5">{celVacio === "vacio" ? "Celular (10 digitos)" : "Celular"}</CFormLabel> */}
                <CFormInput type="number" id="exampleFormControlInput1" placeholder={celVacio === "vacio" ? "Celular (10 digitos)" : "Celular"} aria-describedby="exampleFormControlInputHelpInline"
                  style={celVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => {
                    setCel(e.target.value);

                    e.target.value == "" ?
                      setCelVacio("vacio")
                      :
                      e.target.value.length < 10 ? setCelVacio("vacio")
                        :
                        setCelVacio()
                  }}
                />
                <br />
              </div>

              <div className='col-sm-3'>
                {/* <CFormLabel htmlFor="exampleDropdownFormEmail5">Correo Eletrónico</CFormLabel> */}
                <CFormInput type="email" id="exampleFormControlInput1" placeholder='Correo Eletrónico' aria-describedby="exampleFormControlInputHelpInline" style={emailVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => {
                    setEmail(e.target.value)

                    e.target.value == '' ?
                      setEmailVacio('vacio')
                      :
                      !/\S+@\S+\.\S+/.test(e.target.value) ?
                        setEmailVacio('vacio')
                        :
                        setEmailVacio()
                  }}
                />
              </div>
            </div>
          </div>

          <br />

        </div>

        <br /><br />

        <div className="container">
          <div className="row">

            <div className="col">
              {
                datosCompletos ?
                  <>
                    <div className='fondoCuadrito'>
                      <br />

                      <p>Selecciona tu forma de pago</p>

                      <div className='row'>
                        <div className="col">
                          <CFormCheck checked={useStripe} id="flexCheckDefault" onChange={(e) => { if (e.target.checked) { setUseStripe(true); setUsePaypal(false) } }} />

                          <br />

                          <p>Tarjeta de Crédito / Débito</p>
                        </div>

                        <div className='col'>
                          <CFormCheck checked={usePaypal} id="flexCheckDefault" onChange={(e) => { if (e.target.checked) { setUseStripe(false); setUsePaypal(true) } }} />

                          <br />

                          <p>PayPal</p>
                        </div>
                      </div>

                      <div className='row'>
                        {
                          useStripe ?
                            <><br /><CheckoutStripe codigoFamilia={query.get("codigofamilia")} productoStripe={productoStripe} nombreCompleto={nombreCompleto} email={email} cel={cel} nombres={JSON.stringify(nombres)} /><br /><br /></>
                            :
                            <><CheckoutPaypal codigoFamilia={query.get("codigofamilia")} productoPaypal={productoPaypal} nombreCompleto={nombreCompleto} email={email} cel={cel} nombres={JSON.stringify(nombres)} /></>
                        }
                      </div>

                    </div>
                  </>
                  :
                  <>
                    {/* <CButton className='btnStripe' color='warning' id="checkout-and-portal-button" type="submit" onClick={() => { handleSubmit() }}>Continuar</CButton> */}
                    <img className="img-fluid" style={{ height: 'auto', width: widthBtn }} src={btnContinuar} alt="" onClick={() => { handleSubmit() }}></img>
                  </>
              }
            </div>
          </div>
        </div>

        <br /><br />

        <div style={{ 'fontWeight': 'bold', 'fontSize': '3vh' }}>
          <p>¿Ya tienes una suscripción? Puedes editarla dando <a style={{ color: 'white' }} href="https://tehilatenu.ae.edu.mx/cuenta">click aquí.</a></p>
        </div>

        {/* <div style={{ 'fontWeight': 'bold', 'fontSize': '5vh' }}>
          <p><a href="https://tehilatenu.ae.edu.mx/login"><i className="fa-regular fa-user"></i></a></p>
        </div> */}

        <br />

        <center>
          <img src={LogoYeshiba} alt={"Active Code"} style={{ 'width': widthLogos, "height": "auto" }} />

          <br /> <br /> <br />

          <div className="container_row">
            <div className="layer1">
              <img id='mario' src={mario} alt={"Active Code"} style={{ 'width': "3%", "height": "auto" }} />
            </div>
            <div className="layer2">
              <img src={logoActive} alt={"Active Code"} onClick={logoHandler} style={{ 'width': widthLogos, "height": "auto" }} />
            </div>
          </div>
        </center>

        <br /><br />

        <WhatsAppWidget
          phoneNo="525624229847"
          position="right"
          widgetWidth="300px"
          widgetWidthMobile="260px"
          autoOpen={false}
          autoOpenTimer={5000}
          messageBox={true}
          messageBoxTxt=""
          iconSize="60"
          iconColor="white"
          iconBgColor="#65d071"
          headerIcon="https://res.cloudinary.com/activecode/image/upload/v1673907917/Tehilatenu/zuqgmixmzw4asqqafbdh.png"
          headerIconColor="pink"
          headerTxtColor="black"
          headerBgColor="#377e6a"
          headerTitle="Equipo Tehilatenu"
          headerCaption="en linea"
          bodyBgColor="#d9d5cf"
          chatPersonName=" "
          chatMessage={<>Hola! 👋  Como te puedo ayudar?</>}
          footerBgColor="#f0f2f5"
          placeholder="Escribe un mensaje..."
          btnBgColor="#3f7eeb"
          btnTxt="Enviar"
          btnTxtColor="white"
        />

      </div>
    </>
  )
};

export default LandingDonar;
