import React, { useLayoutEffect, useState } from 'react'
import axios from 'axios'
import Logo from '../assets/logo.png'
import './ListaNombres.css'

function PantallaNombresTodos() {
    let [lista, setLista] = useState([])

    useLayoutEffect(() => {

        let temp = []

        axios.get(`${process.env.REACT_APP_BackURL}/getAllSuscripciones`)

            .then((response) => {

                for (let i in response.data.data) {
                    for (let i2 in response.data.data[i].nombres) {
                        temp.push(response.data.data[i].nombres[i2])
                    }
                }

                setLista(temp)

            })

            .catch((error) => { console.log(error) })
    }, [])


    return (
        <>
            {
                lista.map((item) => {

                    return (

                        <div className="contenidosListaNombres">

                            <img style={{ "width": "25vw", "height": "auto" }} src={Logo} alt="Tehilatenu" onClick={() => { window.location.replace('https://tehilatenu.ae.edu.mx') }} />

                            <h3>לעילוי נשמת זכייה בת סליא</h3>

                            <p className='titulo'>{item.categoria}</p>

                            <h2>{item.nombre}</h2>

                        </div>

                    )
                })
            }
        </>
    )
}

export default PantallaNombresTodos