import React, { useState, useLayoutEffect, useEffect } from 'react';
import { CFormCheck, CFormInput, CButton, CFormLabel, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react'
import { isMobile } from 'react-device-detect'
import axios from 'axios'
import Swal from "sweetalert2"
import Logo from '../assets/logo.png'
import useSound from "use-sound"
import WhatsAppWidget from "react-whatsapp-chat-widget"
import Odometer from 'react-odometerjs'
import dayjs from 'dayjs'

import marioJump from "../sounds/marioJump.mp3"
import "odometer/themes/odometer-theme-train-station.css"
// import "odometer/themes/odometer-theme-car.css"
// import "odometer/themes/odometer-theme-plaza.css"
// import "odometer/themes/odometer-theme-slot-machine.css"
import "react-whatsapp-chat-widget/index.css";
import "react-datepicker/dist/react-datepicker.css";
import './LandingDonar.css'

import LogoYeshiba from '../assets/logoYeshiba.png'
import logoActive from "../assets/LogoActiveAnimated.gif"
import mario from '../assets/mario.png'
import btnContinuar from "../assets/botones/btnContinuar.png"

import btn1Nombre from "../assets/botones/btn1Nombre.png"
import btn1NombreCh from "../assets/botones/btn1NombreCh.png"
import btn3Nombre from "../assets/botones/btn3Nombre.png"
import btn3NombreCh from "../assets/botones/btn3NombreCh.png"
import btn5Nombre from "../assets/botones/btn5Nombre1.png"
import btn5NombreCh from "../assets/botones/btn5NombreCh.png"

function LandingDonar() {
  const [play] = useSound(marioJump)
  let [count, setCount] = useState(1)
  let [perakimLeidos, setPerakimLeidos] = useState()
  let [tehilimCompletos, setTehilimCompletos] = useState()
  // let [totalNombres, setTotalNombres] = useState(0)
  let [totalPersonas, setTotalPersonas] = useState()
  let [unNombre, setUnNombre] = useState(true)
  let [tresNombre, setTresNombre] = useState(false)
  let [cincoNombre, setCincoNombre] = useState(false)
  let [unoPrueba, setUnoPrueba] = useState(false)
  let [cuantos, setCuantos] = useState(1)
  let [datosCompletos, setDatosCompletos] = useState(false)
  let [widthLogos, setWidthLogos] = useState('8%')
  let [widthBtn, setWidthBtn] = useState('30vw')
  let [widthTehilatenu, setWidthTehilatenu] = useState('40vw')
  let [widthbtnsOpciones, setWidthbtnsOpciones] = useState('20vw')
  let [gifts, setGifts] = useState([])

  let [nombre1, setNombre1] = useState('')
  let [nombre2, setNombre2] = useState('')
  let [nombre3, setNombre3] = useState('')
  let [nombre4, setNombre4] = useState('')
  let [nombre5, setNombre5] = useState('')
  let [nombre1Vacio, setNombre1Vacio] = useState()
  let [nombre2Vacio, setNombre2Vacio] = useState()
  let [nombre3Vacio, setNombre3Vacio] = useState()
  let [nombre4Vacio, setNombre4Vacio] = useState()
  let [nombre5Vacio, setNombre5Vacio] = useState()

  let [categoria1, setCategoria1] = useState('Selecciona')
  let [categoria2, setCategoria2] = useState('Selecciona')
  let [categoria3, setCategoria3] = useState('Selecciona')
  let [categoria4, setCategoria4] = useState('Selecciona')
  let [categoria5, setCategoria5] = useState('Selecciona')
  let [categoria1Titulo, setCategoria1Titulo] = useState('Selecciona')
  let [categoria2Titulo, setCategoria2Titulo] = useState('Selecciona')
  let [categoria3Titulo, setCategoria3Titulo] = useState('Selecciona')
  let [categoria4Titulo, setCategoria4Titulo] = useState('Selecciona')
  let [categoria5Titulo, setCategoria5Titulo] = useState('Selecciona')
  let [categoria1Vacio, setCategoria1Vacio] = useState()
  let [categoria2Vacio, setCategoria2Vacio] = useState()
  let [categoria3Vacio, setCategoria3Vacio] = useState()
  let [categoria4Vacio, setCategoria4Vacio] = useState()
  let [categoria5Vacio, setCategoria5Vacio] = useState()

  let [anonimo1, setAnonimo1] = useState(false)
  let [anonimo2, setAnonimo2] = useState(false)
  let [anonimo3, setAnonimo3] = useState(false)
  let [anonimo4, setAnonimo4] = useState(false)
  let [anonimo5, setAnonimo5] = useState(false)

  let [nombres, setNombres] = useState([])

  let [nombreCompleto, setNombreCompleto] = useState('')
  let [cel, setCel] = useState('')
  let [email, setEmail] = useState('')
  const params = new URLSearchParams(location.search)
  let [gift, setGift] = useState(params.get('gift') ? params.get('gift') : '')
  let [nombreCompletoVacio, setNombreCompletoVacio] = useState()
  let [emailVacio, setEmailVacio] = useState()
  let [celVacio, setCelVacio] = useState()
  let [giftVacio, setGiftVacio] = useState()


  function logoHandler() {
    let temp = count

    if (count == 14) {
      play()
      document.getElementById('mario').classList.add("mario")
      setTimeout(() => { document.getElementById('mario').classList.remove("mario") }, 600);
      setCount(1)
    }
    else { temp++; setCount(temp) }
  }

  function handleSubmit() {
    let message = ''
    if (nombreCompleto == '') { message = 'l donador estan incompletos'; setNombreCompletoVacio("vacio") }
    if (email == '') { message = 'l donador estan incompletos'; setEmailVacio("vacio") }
    if (cel == '') { message = 'l donador estan incompletos'; setCelVacio("vacio") }
    if (gift == '') { message = 'l donador estan incompletos'; setGiftVacio("vacio") }

    if (cuantos > 0) {
      if (nombre1 == '') { message = ' los nombres no estan completos'; setNombre1Vacio("vacio") }
      if (categoria1 == 'Selecciona') { message = ' los nombres no estan completos'; setCategoria1Vacio("vacio") }
    }
    if (cuantos > 1) {
      if (nombre2 == '') { message = ' los nombres no estan completos'; setNombre2Vacio("vacio") }
      if (categoria2 == 'Selecciona') { message = ' los nombres no estan completos'; setCategoria2Vacio("vacio") }
      if (nombre3 == '') { message = ' los nombres no estan completos'; setNombre3Vacio("vacio") }
      if (categoria3 == 'Selecciona') { message = ' los nombres no estan completos'; setCategoria3Vacio("vacio") }
    }
    if (cuantos > 3) {
      if (nombre4 == '') { message = ' los nombres no estan completos'; setNombre4Vacio("vacio") }
      if (categoria4 == 'Selecciona') { message = ' los nombres no estan completos'; setCategoria4Vacio("vacio") }
      if (nombre5 == '') { message = ' los nombres no estan completos'; setNombre5Vacio("vacio") }
      if (categoria5 == 'Selecciona') { message = ' los nombres no estan completos'; setCategoria5Vacio("vacio") }
    }

    Swal.fire(`Los datos de${message}, por favor llena todos los campos.`)
  }

  function seleccion(id) {
    let id1
    let id2
    let id3

    if (id == "1Nombre") { id1 = "1Nombre"; id2 = "3Nombre"; id3 = "5Nombre"; }
    if (id == "3Nombre") { id1 = "3Nombre"; id2 = "1Nombre"; id3 = "5Nombre"; }
    if (id == "5Nombre") { id1 = "5Nombre"; id2 = "1Nombre"; id3 = "3Nombre"; }

    document.getElementById(id1).style.setProperty('opacity', '1')
    document.getElementById(id2).style.setProperty('opacity', '.5')
    document.getElementById(id3).style.setProperty('opacity', '.5')
  }

  useLayoutEffect(() => {
    if (isMobile) { setWidthLogos('30vw'); setWidthBtn('100vw'); setWidthTehilatenu('70vw'); setWidthbtnsOpciones('40vw') }

    axios.get(`${process.env.REACT_APP_BackURL}/getAllPerakim`)
      .then((response) => { setPerakimLeidos(response.data.data[0].perakimLeidos); setTotalPersonas(response.data.data[0].personasLeyendo); setTehilimCompletos(response.data.data[0].tehilimCompletos) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllGifts`)
      .then((response) => { setGifts(response.data.data.filter((gift) => gift.is_active).map((gift) => gift.ticket)) })
      .catch((err) => { console.log(err) })

  }, [])

  useEffect(() => {
    let advance = true
    let temp = []

    let uno = [{ "nombre": nombre1, "categoria": categoria1, "anonimo": anonimo1 }]
    let tres = [{ "nombre": nombre1, "categoria": categoria1, "anonimo": anonimo1 }, { "nombre": nombre2, "categoria": categoria2, "anonimo": anonimo2 }, { "nombre": nombre3, "categoria": categoria3, "anonimo": anonimo3 }]
    let cinco = [{ "nombre": nombre1, "categoria": categoria1, "anonimo": anonimo1 }, { "nombre": nombre2, "categoria": categoria2, "anonimo": anonimo2 }, { "nombre": nombre3, "categoria": categoria3, "anonimo": anonimo3 }, { "nombre": nombre4, "categoria": categoria4, "anonimo": anonimo4 }, { "nombre": nombre5, "categoria": categoria5, "anonimo": anonimo5 }]

    if (unNombre) { temp = uno }
    if (tresNombre) { temp = tres }
    if (cincoNombre) { temp = cinco }
    if (unoPrueba) { temp = uno }

    if (!nombreCompleto || !email || !/\S+@\S+\.\S+/.test(email) || !cel || cel.length < 10) {
      if (nombreCompleto == undefined) { setNombreCompletoVacio("vacio") }
      if (email == undefined) { setEmailVacio("vacio") }
      if (cel == undefined) { setCelVacio("vacio") }
      if (gift == undefined) { setGiftVacio("vacio") }

      advance = false
    }

    if (cuantos > 0) {
      if (!nombre1 || categoria1 == 'Selecciona') {

        if (nombre1 == undefined) { setNombre1Vacio("vacio") }

        advance = false
      }
    }

    if (cuantos > 1) {
      if (!nombre2 || categoria2 == 'Selecciona' || !nombre3 || categoria3 == 'Selecciona') {

        if (nombre2 == undefined) { setNombre2Vacio("vacio") }
        if (nombre3 == undefined) { setNombre3Vacio("vacio") }

        advance = false
      }
    }

    if (cuantos > 3) {
      if (!nombre4 || categoria4 == 'Selecciona' || !nombre5 || categoria5 == 'Selecciona') {

        if (nombre4 == undefined) { setNombre4Vacio("vacio") }
        if (nombre5 == undefined) { setNombre5Vacio("vacio") }

        advance = false
      }
    }

    setNombres(temp)

    if (advance) { setDatosCompletos(true) }
    else { setDatosCompletos(false) }
  }, [nombreCompleto, cel, email, unNombre, tresNombre, cincoNombre, nombre1, categoria1, anonimo1, nombre2, categoria2, anonimo2, nombre3, categoria3, anonimo3, nombre4, categoria4, anonimo4, nombre5, categoria5, anonimo5])

  function insertSuscripcionGratis(data) {

    gifts.includes(gift) || gift == "YOXISRAEL" ?

      axios.post(`${process.env.REACT_APP_BackURL}/insertSuscripcionGratis`, data)
        .then(() => {

          Swal.fire(`Suscripcion realizada con exito!`)
            .then(() => {

              axios.patch(`${process.env.REACT_APP_BackURL}/updateGift?ticket=${gift}`, { is_active: false })
                .then(() => { window.location.replace('https://tehilatenu.ae.edu.mx/gracias') })
                .catch((err) => { console.log(err) })

            })
            .catch((err) => { console.log(err) })
        })
        .catch((err) => { console.log(err) })

      :

      Swal.fire(`Codigo gift incorrecto ó ya fue usado`)


  }

  return (
    <>
      <div className='centrado'>

        <img style={{ "width": widthTehilatenu, "height": "auto" }} src={Logo} alt="" />

        <br />

        <h1>לעילוי נשמת זכייה בת סליא</h1>

        <br />

        <div className='fondoCuadrito'>

          <div className="container overflow-hidden text-center">

            <p>En Tehilatenu actualmente tenemos:</p>
            <br />

            <div className="row">
              <div className="col-md">
                <center>
                  Perakim de Tehilim leidos
                  <div className="block">
                    <Odometer value={perakimLeidos} format="d" />
                  </div>
                </center>
                <br />
              </div>

              <div className="col-md">
                <center>
                  Cadenas de Tehilim
                  <div className="block">
                    <Odometer value={tehilimCompletos} format="d" />
                  </div>
                </center>
                <br />
              </div>

              <div className="col-md">
                <center>
                  Niñ@s rezando diariamente
                  <div className="block">
                    <Odometer value={totalPersonas} format="d" />
                  </div>
                  <br />
                </center>
              </div>

              {/*<p>Tenemos un total de {totalNombres} nombres por los cuales se lee el tehilim.</p> */}
            </div>
          </div>
        </div>

        <br /><br />

        <div className='fondoCuadrito'>

          <br />

          <p>¡UNAMOS NUESTRAS TEFILOT!</p>

          <p>¿Te interesa que {totalPersonas} niñ@s pidan diariamente por ti?</p>

          <br />

          <p>Ingresa tu codigo</p>

          <div className='gift'>
            <CFormInput value={params.get('gift') ? params.get('gift') : null} type='text' placeholder='Codigo de gift' style={giftVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
              onChange={(e) => { setGift(e.target.value.toUpperCase()); e.target.value != '' ? setGiftVacio() : setGiftVacio('vacio') }}
            />
          </div>

          <br />

          <p> Elige tu opción</p>

          <br />

          <div className='row'>

            <div className="col-lg" style={{ marginBottom: "5%" }} id="btns">
              <img id="1Nombre" style={{ "width": widthbtnsOpciones, "height": "auto" }} src={!isMobile ? btn1Nombre : btn1NombreCh} alt="" onClick={() => { seleccion("1Nombre"); setUnoPrueba(false); setUnNombre(true); setTresNombre(false); setCincoNombre(false); setCuantos(1) }} />
            </div>

            <div className="col-lg" style={{ marginBottom: "5%" }} id="btns">
              <img id="3Nombre" style={{ "width": widthbtnsOpciones, "height": "auto" }} src={!isMobile ? btn3Nombre : btn3NombreCh} alt="" onClick={() => { seleccion("3Nombre"), setUnoPrueba(false); setUnNombre(false); setTresNombre(true); setCincoNombre(false); setCuantos(3) }} />
            </div>

            <div className="col-lg" style={{ marginBottom: "5%" }} id="btns">
              <img id="5Nombre" style={{ "width": widthbtnsOpciones, "height": "auto" }} src={!isMobile ? btn5Nombre : btn5NombreCh} alt="" onClick={() => { seleccion("5Nombre"); setUnoPrueba(false); setUnNombre(false); setTresNombre(false); setCincoNombre(true); setCuantos(5); }} />
            </div>

          </div>

          <br />

          {
            cuantos > 0 ?
              <>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6">
                      <CFormInput type="text" id="exampleFormControlInput1" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                        value={nombre1}
                        style={nombre1Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                        onChange={(e) => { setNombre1(e.target.value); e.target.value != '' ? setNombre1Vacio() : setNombre1Vacio('vacio') }}
                      />
                      <br />
                    </div>

                    <div className="col-sm-4">
                      <CFormLabel htmlFor="exampleDropdownFormEmail1">Categoría</CFormLabel>

                      <br />

                      <CDropdown>
                        <CDropdownToggle color={categoria1Vacio == 'vacio' ? 'danger' : 'light'}>{categoria1Titulo}</CDropdownToggle>
                        <CDropdownMenu onClick={(e) => { setCategoria1(e.target.id); setCategoria1Vacio(); setCategoria1Titulo(e.target.text) }}>
                          <CDropdownItem id='Beraja y Hatzlaja'>Shemira (protección)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Pidion Shebuim (pronto regreso)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Jayal (soldado)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                          <CDropdownItem id='Refua Shelema'>Refua Shelema</CDropdownItem>
                          <CDropdownItem id='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                          <CDropdownItem id='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                          <CDropdownItem id='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                          <CDropdownItem id='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                          <CDropdownItem id='Agradecimiento'>Agradecimiento</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>

                    <div className="col-sm-2">

                      {isMobile ? <br /> : null}

                      <CFormLabel htmlFor="exampleDropdownFormEmail1">Anónimo</CFormLabel>

                      <br />

                      <CFormCheck checked={anonimo1} id="flexCheckDefault" onChange={() => { anonimo1 ? setAnonimo1(false) : setAnonimo1(true) }} />
                    </div>
                  </div>
                  <br />
                </div>

              </>

              : null
          }

          {
            cuantos > 1 ?
              <>
                <div className='container'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <CFormInput type="text" id="exampleFormControlInput2" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                        value={nombre2}
                        style={nombre2Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                        onChange={(e) => { setNombre2(e.target.value); e.target.value != '' ? setNombre2Vacio() : setNombre2Vacio('vacio') }}
                      />
                      <br />
                    </div>

                    <div className='col-sm-4'>
                      <CFormLabel htmlFor="exampleDropdownFormEmail2">Categoria</CFormLabel>

                      <br />

                      <CDropdown>
                        <CDropdownToggle color={categoria2Vacio == 'vacio' ? 'danger' : 'light'}>{categoria2Titulo}</CDropdownToggle>
                        <CDropdownMenu onClick={(e) => { setCategoria2(e.target.id); setCategoria2Vacio(); setCategoria2Titulo(e.target.text) }}>
                          <CDropdownItem id='Beraja y Hatzlaja'>Shemira (protección)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Pidion Shebuim (pronto regreso)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Jayal (soldado)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                          <CDropdownItem id='Refua Shelema'>Refua Shelema</CDropdownItem>
                          <CDropdownItem id='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                          <CDropdownItem id='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                          <CDropdownItem id='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                          <CDropdownItem id='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                          <CDropdownItem id='Agradecimiento'>Agradecimiento</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>

                    <div className='col-sm-2'>

                      {isMobile ? <br /> : null}

                      <CFormLabel htmlFor="exampleDropdownFormEmail3">Anonimo</CFormLabel>

                      <br />

                      <CFormCheck checked={anonimo2} id="flexCheckDefault" onChange={() => { anonimo2 ? setAnonimo2(false) : setAnonimo2(true) }} />
                    </div>
                  </div>

                  <br />

                </div>

                <div className='container'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <CFormInput type="text" id="exampleFormControlInput3" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                        value={nombre3}
                        style={nombre3Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                        onChange={(e) => { setNombre3(e.target.value); e.target.value != '' ? setNombre3Vacio() : setNombre3Vacio('vacio') }}
                      />
                      <br />
                    </div>

                    <div className='col-sm-4'>
                      <CFormLabel htmlFor="exampleDropdownFormEmail3">Categoria</CFormLabel>

                      <br />

                      <CDropdown>
                        <CDropdownToggle color={categoria3Vacio == 'vacio' ? 'danger' : 'light'}>{categoria3Titulo}</CDropdownToggle>
                        <CDropdownMenu onClick={(e) => { setCategoria3(e.target.id); setCategoria3Vacio(); setCategoria3Titulo(e.target.text) }}>
                          <CDropdownItem id='Beraja y Hatzlaja'>Shemira (protección)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Pidion Shebuim (pronto regreso)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Jayal (soldado)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                          <CDropdownItem id='Refua Shelema'>Refua Shelema</CDropdownItem>
                          <CDropdownItem id='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                          <CDropdownItem id='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                          <CDropdownItem id='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                          <CDropdownItem id='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                          <CDropdownItem id='Agradecimiento'>Agradecimiento</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>

                    <div className='col-sm-2'>

                      {isMobile ? <br /> : null}

                      <CFormLabel htmlFor="exampleDropdownFormEmail3">Anonimo</CFormLabel>

                      <br />

                      <CFormCheck checked={anonimo3} id="flexCheckDefault" onChange={() => { anonimo3 ? setAnonimo3(false) : setAnonimo3(true) }} />
                    </div>
                  </div>
                  <br />
                </div>
              </>

              : null
          }

          {
            cuantos > 3 ?
              <>
                <div className='container'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <CFormInput type="text" id="exampleFormControlInput4" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                        value={nombre4}
                        style={nombre4Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                        onChange={(e) => { setNombre4(e.target.value); e.target.value != '' ? setNombre4Vacio() : setNombre4Vacio('vacio') }}
                      />
                      <br />
                    </div>

                    <div className='col-sm-4'>
                      <CFormLabel htmlFor="exampleDropdownFormEmail4">Categoria</CFormLabel>

                      <br />

                      <CDropdown>
                        <CDropdownToggle color={categoria4Vacio == 'vacio' ? 'danger' : 'light'}>{categoria4Titulo}</CDropdownToggle>
                        <CDropdownMenu onClick={(e) => { setCategoria4(e.target.id); setCategoria4Vacio(); setCategoria4Titulo(e.target.text) }}>
                          <CDropdownItem id='Beraja y Hatzlaja'>Shemira (protección)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Pidion Shebuim (pronto regreso)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Jayal (soldado)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                          <CDropdownItem id='Refua Shelema'>Refua Shelema</CDropdownItem>
                          <CDropdownItem id='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                          <CDropdownItem id='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                          <CDropdownItem id='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                          <CDropdownItem id='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                          <CDropdownItem id='Agradecimiento'>Agradecimiento</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>

                    <div className='col-sm-2'>

                      {isMobile ? <br /> : null}

                      <CFormLabel htmlFor="exampleDropdownFormEmail4">Anonimo</CFormLabel>

                      <br />

                      <CFormCheck checked={anonimo4} id="flexCheckDefault" onChange={() => { anonimo4 ? setAnonimo4(false) : setAnonimo4(true) }} />
                    </div>
                  </div>
                  <br />
                </div>

                <div className='container'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <CFormInput type="text" id="exampleFormControlInput5" label="Tefilá para" placeholder="Abraham ben Sara" aria-describedby="exampleFormControlInputHelpInline"
                        value={nombre5}
                        style={nombre5Vacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                        onChange={(e) => { setNombre5(e.target.value); e.target.value != '' ? setNombre5Vacio() : setNombre5Vacio('vacio') }}
                      />
                      <br />
                    </div>

                    <div className='col-sm-4'>
                      <CFormLabel htmlFor="exampleDropdownFormEmail5">Categoria</CFormLabel>

                      <br />

                      <CDropdown>
                        <CDropdownToggle color={categoria5Vacio == 'vacio' ? 'danger' : 'light'}>{categoria5Titulo}</CDropdownToggle>
                        <CDropdownMenu onClick={(e) => { setCategoria5(e.target.id); setCategoria5Vacio(); setCategoria5Titulo(e.target.text) }}>
                          <CDropdownItem id='Beraja y Hatzlaja'>Shemira (protección)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Pidion Shebuim (pronto regreso)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Jayal (soldado)</CDropdownItem>
                          <CDropdownItem id='Beraja y Hatzlaja'>Beraja y Hatzlaja</CDropdownItem>
                          <CDropdownItem id='Refua Shelema'>Refua Shelema</CDropdownItem>
                          <CDropdownItem id='Parnasa Toba'>Parnasa Toba</CDropdownItem>
                          <CDropdownItem id='Zivug Hagun'>Zivug Hagun</CDropdownItem>
                          <CDropdownItem id='Zera Shel Kayama'>Zera Shel Kayama</CDropdownItem>
                          <CDropdownItem id='Leiluy Nishmat'>Leiluy Nishmat</CDropdownItem>
                          <CDropdownItem id='Agradecimiento'>Agradecimiento</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>

                    <div className='col-sm-2'>

                      {isMobile ? <br /> : null}

                      <CFormLabel htmlFor="exampleDropdownFormEmail5">Anonimo</CFormLabel>

                      <br />

                      <CFormCheck checked={anonimo5} id="flexCheckDefault" onChange={() => { anonimo5 ? setAnonimo5(false) : setAnonimo5(true) }} />
                    </div>
                  </div>
                  <br />
                </div>

              </>

              : null
          }
        </div>

        <br /><br />

        <div className='fondoCuadrito'>

          <br />

          <p>Ingresa tus datos</p>

          <div className='container'>
            <div className='row'>

              <div className='col-sm-6'>
                <CFormInput type="text" id="exampleFormControlInput1" placeholder="Nombre Completo" aria-describedby="exampleFormControlInputHelpInline"
                  style={nombreCompletoVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => { setNombreCompleto(e.target.value); e.target.value != '' ? setNombreCompletoVacio() : setNombreCompletoVacio('vacio') }}
                />
                <br />
              </div>

              <div className='col-sm-3'>
                <CFormInput type="number" id="exampleFormControlInput1" placeholder={celVacio === "vacio" ? "Celular (10 digitos)" : "Celular"} aria-describedby="exampleFormControlInputHelpInline"
                  style={celVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => {
                    setCel(e.target.value);

                    e.target.value == "" ?
                      setCelVacio("vacio")
                      :
                      e.target.value.length < 10 ? setCelVacio("vacio")
                        :
                        setCelVacio()
                  }}
                />
                <br />
              </div>

              <div className='col-sm-3'>
                <CFormInput type="email" id="exampleFormControlInput1" placeholder='Correo Eletrónico' aria-describedby="exampleFormControlInputHelpInline" style={emailVacio == 'vacio' ? { "backgroundColor": "rgba(250, 86, 119, .7)" } : null}
                  onChange={(e) => {
                    setEmail(e.target.value)

                    e.target.value == '' ?
                      setEmailVacio('vacio')
                      :
                      !/\S+@\S+\.\S+/.test(e.target.value) ?
                        setEmailVacio('vacio')
                        :
                        setEmailVacio()
                  }}
                />
                <br />
              </div>

            </div>

          </div>


        </div>

        <br /><br />

        <div className="container">
          <div className="row">

            <div className="col">
              {
                datosCompletos ?
                  <>
                    <div className='fondoCuadrito'>

                      <CButton className='btnStripe' onClick={() => { insertSuscripcionGratis({ nombreCompleto, email, cel, nombres, fechaVencimiento: dayjs().add(1, 'month').format("DD/MM/YYYY") }) }} color='warning'>Suscribirme</CButton>

                    </div>
                  </>

                  :
                  <>
                    <img className="img-fluid" style={{ height: 'auto', width: widthBtn }} src={btnContinuar} alt="" onClick={() => { handleSubmit() }}></img>
                  </>
              }
            </div>
          </div>
        </div>

        <br /><br />

        <div style={{ 'fontWeight': 'bold', 'fontSize': '3vh' }}>
          <p>¿Ya tienes una suscripción? Puedes editarla dando <a style={{ color: 'white' }} href="https://tehilatenu.ae.edu.mx/cuenta">click aquí.</a></p>
        </div>

        <br />

        <center>
          <img src={LogoYeshiba} alt={"Active Code"} style={{ 'width': widthLogos, "height": "auto" }} />

          <br /> <br /> <br />

          <div className="container_row">
            <div className="layer1">
              <img id='mario' src={mario} alt={"Active Code"} style={{ 'width': "3%", "height": "auto" }} />
            </div>
            <div className="layer2">
              <img src={logoActive} alt={"Active Code"} onClick={logoHandler} style={{ 'width': widthLogos, "height": "auto" }} />
            </div>
          </div>
        </center>

        <br /><br />

        <WhatsAppWidget
          phoneNo="525624229847"
          position="right"
          widgetWidth="300px"
          widgetWidthMobile="260px"
          autoOpen={false}
          autoOpenTimer={5000}
          messageBox={true}
          messageBoxTxt=""
          iconSize="60"
          iconColor="white"
          iconBgColor="#65d071"
          headerIcon="https://res.cloudinary.com/activecode/image/upload/v1673907917/Tehilatenu/zuqgmixmzw4asqqafbdh.png"
          headerIconColor="pink"
          headerTxtColor="black"
          headerBgColor="#377e6a"
          headerTitle="Equipo Tehilatenu"
          headerCaption="en linea"
          bodyBgColor="#d9d5cf"
          chatPersonName=" "
          chatMessage={<>Hola! 👋  Como te puedo ayudar?</>}
          footerBgColor="#f0f2f5"
          placeholder="Escribe un mensaje..."
          btnBgColor="#3f7eeb"
          btnTxt="Enviar"
          btnTxtColor="white"
        />

      </div>
    </>
  )
};

export default LandingDonar;
