import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { CButton, CFormLabel, CForm, CFormInput, CModal, CAlert, CModalFooter, CModalHeader, CModalTitle, CModalBody, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import axios from "axios";
import Swal from "sweetalert2";
import ReactLoading from 'react-loading';
import "../../index.css";
import { AuthContext } from "../../contexts/AuthContext";

const Usuarios = () => {
  const { user1 } = useContext(AuthContext);
  let [loading, setLoading] = useState(true)
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [visible3, setVisible3] = useState(false)
  // let navigate = useNavigate();
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [visible, setVisible] = useState(false)
  let [usersOG, setUsersOG] = useState([])
  let [users, setUsers] = useState([])

  function getUsers() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllUsers`, { headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}` } })
      .then((response) => { setUsers(response.data); setUsersOG(response.data); setLoading(false) })
      .catch((err) => { console.log(err) })
  }

  const handleForm = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) { Swal.fire({ icon: "error", title: "Verifica tu contraseña", allowEscapeKey: true }) }

    else {
      try {
        await axios.post(`${process.env.REACT_APP_BackURL}/signUp`, { email, password, }, { headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}` } })
          .then(() => {
            setEmail(""); setPassword("")

            Swal.fire({ icon: "success", title: "Usuario creado con exito", timer: 2000, timerProgressBar: true, allowEscapeKey: true })
              .then(window.location.reload())
          });
      }
      catch (error) {
        let message = error.response.data.message

        Swal.fire({ icon: "error", title: "No se puede crear usuario ", text: "Intenta de nuevo por favor." + message, timer: 2000, timerProgressBar: true, allowEscapeKey: true })
      }
    };
  }

  function desactivar(id) {
    axios.patch(`${process.env.REACT_APP_BackURL}/updateUser/${id}`, { is_active: false }, { headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}` } })
      .then(() => { setVisible1(true); getUsers() })
      .catch((err) => { setVisible3(true) })
  }

  function activar(id) {
    axios.patch(`${process.env.REACT_APP_BackURL}/updateUser/${id}`, { is_active: true }, { headers: { Authorization: `Bearer: ${localStorage.getItem("app_token")}` } })
      .then(() => { setVisible2(true); getUsers() })
      .catch((err) => { setVisible3(true) })
  }

  // handle change event of search input
  const handleChange = (value) => {
    filterData(value)
  };

  // // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setUsers(usersOG);
    else {
      const filteredData = usersOG.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setUsers(filteredData);
    }
  };

  useLayoutEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    if (visible1) { setTimeout(() => { setVisible1(false) }, 5000); }
    if (visible2) { setTimeout(() => { setVisible2(false) }, 5000); }
    if (visible3) { setTimeout(() => { setVisible3(false) }, 5000); }
  }, [visible1, visible2, visible3])

  return (
    <>
      <div className="centro">
        <CAlert color="warning" dismissible visible={visible1} onClose={() => { getUsers(); setVisible1(false) }}>Usuario Desactivado!</CAlert>
        <CAlert color="primary" dismissible visible={visible2} onClose={() => { getUsers(); setVisible2(false) }}>Usuario Activado!</CAlert>
        <CAlert color="danger" dismissible visible={visible3} onClose={() => { setVisible3(false) }}>Ocurio un error, intenta de nuevo por favor.</CAlert>

        <div style={{ 'float': 'right' }}>
          <>
            <CButton onClick={() => setVisible(!visible)}>Nuevo Usuario</CButton>

            <CModal visible={visible} onClose={() => setVisible(false)}>
              <CModalHeader onClose={() => setVisible(false)}>
                <CModalTitle>Nuevo Usuario</CModalTitle>
              </CModalHeader>

              <CModalBody>
                <CForm className="container form-regis" onSubmit={handleForm}>
                  <CFormLabel>Email</CFormLabel>
                  <CFormInput type="email" name="email" id="exampleEmail" required value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                  />

                  <br />

                  <CFormLabel>Contraseña</CFormLabel>
                  <CFormInput type="password" required value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                  />

                  <br />

                  <CFormLabel>Verificar contraseña</CFormLabel>
                  <CFormInput type="password" required value={confirmPassword}
                    onChange={(e) => { setConfirmPassword(e.target.value) }}
                  />

                  <br />

                  <div className="d-flex flex-row-reverse">
                    <CButton color="danger" onClick={() => { setVisible(false), setEmail(""), setPassword(""), setConfirmPassword("") }}>Cancelar</CButton>
                    <CButton type="submit" color="info">Guardar</CButton>
                  </div>

                </CForm>
              </CModalBody>
            </CModal>
          </>
        </div>

        <center>
          <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { handleChange(e.target.value) })} />
        </center>

        <br />

        {
          !loading ?
            <>
              <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
                <CTableHead>
                  <CTableRow color='info'>
                    <CTableHeaderCell>Creado</CTableHeaderCell>
                    <CTableHeaderCell>Usuario</CTableHeaderCell>
                    <CTableHeaderCell>Status</CTableHeaderCell>
                    <CTableHeaderCell></CTableHeaderCell>
                    {/* <CTableHeaderCell>Cantidad</CTableHeaderCell> */}
                    {/* <CTableHeaderCell>Nombres</CTableHeaderCell>
            <CTableHeaderCell></CTableHeaderCell> */}
                  </CTableRow>
                </CTableHead>

                <CTableBody>
                  {
                    users.length > 0 ?
                      <>
                        {
                          users.map((item) => {
                            return (
                              <>
                                <CTableRow>
                                  <CTableDataCell>{new Date(item.createdAt).toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' })}</CTableDataCell>
                                  <CTableDataCell>{item.email}</CTableDataCell>
                                  <CTableDataCell>{item.is_active ? 'Activo' : 'Inactivo'}</CTableDataCell>
                                  {/* <CTableDataCell>{item.cantidad}</CTableDataCell> */}
                                  {/* <CTableDataCell><CButton color='info' shape="rounded-pill" onClick={() => { setVisible1(true); setNombresDisplay(item.nombres) }}><CIcon icon={cilAddressBook} size="xl" /></CButton></CTableDataCell>
                          <CTableDataCell><CButton color='success' shape="rounded-pill" onClick={() => { setVisible2(true); setDatos(item); setAcabo(true) }}>Editar</CButton></CTableDataCell> */}
                                  {
                                    item.email != user1.email ?
                                      item.is_active
                                        ?
                                        <><CTableDataCell><CButton color='danger' shape="rounded-pill" onClick={() => { desactivar(item._id) }}>Desactivar</CButton></CTableDataCell></>
                                        :
                                        <><CTableDataCell><CButton color='success' shape="rounded-pill" onClick={() => { activar(item._id) }}>Activar</CButton></CTableDataCell></>
                                      :
                                      <><CTableDataCell>En uso</CTableDataCell></>
                                  }
                                </CTableRow>
                              </>
                            )
                          })
                        }
                      </>
                      :
                      <>
                        <CTableRow>
                          <CTableDataCell colSpan="7">Sin Resultados</CTableDataCell>
                        </CTableRow>
                      </>
                  }

                </CTableBody>
              </CTable>
            </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }
      </div>
    </>
  );
};

export default Usuarios;
