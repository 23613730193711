import React, { useState, useEffect, useLayoutEffect } from 'react';
import { CButton } from '@coreui/react';
import './CheckoutStripe.css'

const ProductDisplay = (props) => (
  <section>
    <form action={`${process.env.REACT_APP_BackURL}/create-checkout-session`} method="POST">
      {/* Add a hidden field with the lookup_key of your Price */}
      <input type="hidden" name="priceId" value={props.productoStripe} />
      <input type="hidden" name="nombreCompleto" value={props.nombreCompleto} />
      <input type="hidden" name="email" value={props.email} />
      <input type="hidden" name="cel" value={props.cel} />
      <input type="hidden" name="nombres" value={props.nombres} />
      <input type="hidden" name="codigoFamilia" value={props.codigoFamilia} />
      <CButton className='btnStripe' id="checkout-and-portal-button" type="submit" color='warning'>Pagar con tarjeta</CButton>
    </form>
  </section>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <form action={`${process.env.REACT_APP_BackURL}/create-portal-session`} method="POST">
        <input type="hidden" id="session-id" name="session_id" value={sessionId} />
        <CButton id="checkout-and-portal-button" type="submit">Edita tu suscripcion</CButton>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section><p>{message}</p></section>
);

function CheckoutStripe(props) {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("Cancelaste, piensalo te conviene.");
    }
  }, [sessionId]);

  if (!success && message === '') {
    return <ProductDisplay codigoFamilia={props.codigoFamilia} productoStripe={props.productoStripe} nombreCompleto={props.nombreCompleto} email={props.email} cel={props.cel} nombres={props.nombres} />;
  }
  else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  }
  else {
    // aca nunca entra porq comentamos el cancel_url en el back
    return <Message message={message} />;
  }
}

export default CheckoutStripe
