import React, { useState, useLayoutEffect } from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import axios from 'axios';

/*
https://medium.com/@clever.tech.memes/multiple-paypal-payment-subscription-plans-in-react-step-by-step-tutorial-using-official-ba41e027f1c5
*/

function CheckoutPaypal(props) {
    useLayoutEffect(() => {
        // console.log(props)
    }, [])

    const [errorMlessage, setErrorMessage] = useState("");

    const onError = (data, actions) => { setErrorMessage("Ocurrio un error, intenta de nuevo por favor.") }

    const createSubscription = (data, actions) => {
        // console.log("create subscription func!");
        // console.log(data);
        // console.log(actions);
        return actions.subscription.create({
            // plan_id: 'P-1VU01438FL737163XMOZYDRA', // aqui vamos a cambiar el Id 1 3 o 5 personas
            plan_id: props.productoPaypal,
        });
    };

    const onApprove = (data, detail) => {
        // checar si lalama algun webhook, si no, sumar aca el desctno del primer pago, si si sumarlo alla como en stripe
        // console.log(data.subscriptionID);

        let precio = 0
        if (props.productoPaypal == 'P-0UY47575V7123523EMPDTB2Y') { precio = 250 }
        if (props.productoPaypal == 'P-5LE6335074014160CMPDTCTI') { precio = 400 }
        if (props.productoPaypal == 'P-1R50058167195834TMPDTDHA') { precio = 500 }
        if (props.productoPaypal == 'P-77041520G6435432CMPDTD2Q') { precio = 1 }

        // Entra aca cuando pasa el primer pago
        let body = {
            event_type: 'BILLING.SUBSCRIPTION.CREATED2',
            mario: process.env.REACT_APP_MARIO,
            metadata: {
                abrejID: props.abrejID,
                donadorID: '',
                nombreCompleto: props.nombreCompleto,
                cantidad: precio,
                email: props.email,
                cel: props.cel,
                nombres: props.nombres,
                codigoFamilia: props.codigoFamilia,
                suscripcionPaypalID: data.subscriptionID
            }
        }

        axios.post(`${process.env.REACT_APP_BackURL}/webhookPaypal`, body)
            .then(() => { window.location.replace('https://tehilatenu.ae.edu.mx/gracias') })
            .catch((err) => { Swal.fire('Ocurrio un error, intenta de nuevo por favor.') })
    };

    return (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, intent: "subscription", vault: true }}>
            <PayPalButtons
                style={{ shape: 'pill', color: 'blue', layout: 'horizontal', label: 'paypal' }}
                createSubscription={createSubscription}
                onApprove={onApprove}
                onError={onError}
            >
                PayPal Button
            </PayPalButtons>
        </PayPalScriptProvider>
    )
}

export default CheckoutPaypal
