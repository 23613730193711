import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";
import { CButton, CModal, CFormInput, CModalHeader, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CForm, CFormLabel, CFormSwitch, CFormSelect, CModalTitle, CAlert, CModalBody, CModalFooter, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import { cilAddressBook } from '@coreui/icons';
import Swal from "sweetalert2";
import CIcon from '@coreui/icons-react'
import axios from 'axios'
import base64 from 'base-64';
import ReactLoading from 'react-loading'
import Logo from '../../assets/logo.png'
import LogoYeshiba from '../../assets/logoYeshiba.png'
import logoActive from "../../assets/LogoActiveAnimated.gif";
import mario from '../../assets/mario.png'
import useSound from "use-sound";
import marioJump from "../../sounds/marioJump.mp3";

import './ForgotPassword.css'

function ForgotPassword(props) {
  let [play] = useSound(marioJump)
  let [count, setCount] = useState(0)
  let location = useLocation()
  let params = new URLSearchParams(location.search)
  let [loading, setLoading] = useState(true)
  let [loading2, setLoading2] = useState(false)
  let [error, setError] = useState(false)

  let [userData, setUserData] = useState({})
  let [pass1, setPass1] = useState()
  let [pass2, setPass2] = useState()

  function logoHandler() {
    let temp = count

    if (count == 14) {
      play()
      document.getElementById('mario').classList.add("mario")
      setTimeout(() => { document.getElementById('mario').classList.remove("mario") }, 600);
      setCount(1)
    }
    else { temp++; setCount(temp) }
  }

  function handleSubmit() {
    setLoading2(true)

    if (!pass1 || !pass2) { Swal.fire({ icon: "error", iconColor: 'red', text: 'No has llenado todos los campos, revisa los datos e intenta de nuevo.' }); setLoading2(false) }
    else if (pass1 != pass2) { Swal.fire({ icon: "error", iconColor: 'red', text: 'Las contraseñas no coinciden, revisa los datos e intenta de nuevo.' }); setLoading2(false) }
    else {
      axios.patch(`${process.env.REACT_APP_BackURL}/changePass2`, { mail: userData.mail, mario: userData.mario, newpassword: pass1 })
        .then(() => { Swal.fire('Contraseña actualizada con exito!').then(() => { setLoading2(false); window.location.replace('https://tehilatenu.ae.edu.mx/login') }) })
        .catch((err) => { console.log(err); Swal.fire('Ocurrio un error, intenta de nuevo por favor.'); setLoading2(false) })
    }
  }

  useLayoutEffect(() => {
    if (params.get("LNK")) {
      let decoded = (JSON.parse(base64.decode(params.get("LNK"))))

      if (new Date().getTime() > decoded.time) { setError(true) }

      setLoading(false); setUserData(decoded)
    }
  }, [])

  return (
    <>
      {
        !loading ?
          <>
            <div className='contenidosForgot'>
              <img className="img-fluid" src={Logo} alt="" />

              <br />

              <h1>לעילוי נשמת זכייה בת סליא</h1>

              {
                !error ?
                  <>
                    <div style={{ width: '50%', margin: 'auto', padding: '5%' }}>

                      <p>Reestablece tu contraseña</p>

                      <br /><br />

                      <CFormInput type="password" id="exampleFormControlInput1" label="Contraseña Nueva" placeholder="" aria-describedby="exampleFormControlInputHelpInline"
                        onChange={(e) => { setPass1(e.target.value) }}
                      />

                      <br />

                      <CFormInput type="password" id="exampleFormControlInput1" label='Repite tu Contraseña Nueva' placeholder="" aria-describedby="exampleFormControlInputHelpInline"
                        onChange={(e) => { setPass2(e.target.value) }}
                      />

                      <br /><br /><br />

                      {
                        !loading2 ?
                          <><CButton color="info" onClick={() => { handleSubmit() }}>Guardar</CButton></>
                          :
                          <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
                      }

                    </div>
                  </>
                  :
                  <><p style={{ "fontWeight": "bold", "color": "red", 'padding': '5%' }}>Ocurrio un error, por favor intetenta nuevamente.</p></>
              }

              <center>
                <img src={LogoYeshiba} alt={"Active Code"} style={{ 'width': "8%", "height": "auto" }} />

                <br /> <br /> <br />

                <div className="container_row">
                  <div className="layer1">
                    <img id='mario' src={mario} alt={"Active Code"} style={{ 'width': "3%", "height": "auto" }} />
                  </div>
                  <div className="layer2">
                    <img src={logoActive} alt={"Active Code"} onClick={logoHandler} style={{ 'width': "8%", "height": "auto" }} />
                  </div>
                </div>
              </center>

              <br /><br />
            </div>
          </>
          : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
      }
    </>
  );
}

export default ForgotPassword