import React, { useLayoutEffect, useState } from 'react'
import ListaNombres from './ListaNombres'
import Logo from '../assets/logo.png'
import './ListaNombres.css'

function PantallaNombresSinAnonimo() {
    const query = new URLSearchParams(location.search)
    let [vertical, setVertical] = useState(query.get("ZLDA") == 'horizontal' ? false : true)
    let [cantidadNombres, setCantidadNombres] = useState(15)

    useLayoutEffect(() => {
        // setTimeout(() => { window.location.reload() }, 3600000 * 3)

        if (vertical) { setCantidadNombres(17) }
    }, [])

    return (
        <>
            <div className="contenidosListaNombres">
                <div className='vertical'>

                    <img style={{ "width": "25vw", "height": "auto" }} src={Logo} alt="Tehilatenu" onClick={() => { window.location.replace('https://tehilatenu.ae.edu.mx') }} />

                    <br /><br />

                    <h4>לעילוי נשמת זכייה בת סליא</h4>

                    <ListaNombres cantidadNombres={cantidadNombres} anonimo={'no mostrar'} />
                </div>
            </div>
        </>
    )
}

export default PantallaNombresSinAnonimo