import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  return (
    <CFooter>
      <div>
        <img style={{"height":"5vh"}} alt='Active Code' src="https://res.cloudinary.com/activecode/image/upload/v1656874398/Logo.jpg" />

        <a href="https://supportac.netlify.app/" target="_blank" rel="noopener noreferrer">
          Active Code
        </a>

        <span className="ms-1">&copy; {new Date().getFullYear()}</span>
      </div>
      {/* <div className="ms-auto">
        <span className="me-1">Powered by</span>
        <a href="https://coreui.io/react" target="_blank" rel="noopener noreferrer">
          CoreUI React Admin &amp; Dashboard Template
        </a>
      </div> */}
    </CFooter>
  )
}

export default React.memo(AppFooter)
